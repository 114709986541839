import axios from "axios";
import { setBaseURL, setContentTypeHeader } from "../utils/axiosHelper";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default class BaseController {
  constructor() {
    this.BASE_URL = BASE_URL;
    this.AbortControllers = [];

    // Set default content type header
    setContentTypeHeader("application/json");
    setBaseURL(BASE_URL);
  }

  addQueryParameters = (url, params) => {
    const query = Object.keys(params).map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    );

    return query.length === 0 ? url : `${url}?${query.join("&")}`;
  };

  abortAllRequests = () => {
    if (this.AbortControllers.length > 0) {
      this.AbortControllers.forEach((controller) => {
        controller.abort();
      });
    }
  };

  sendRequest = async (method, url, params, body, headers, file) => {
    const controller = new AbortController();
    this.AbortControllers.push(controller);

    let config = {
      method: method,
      signal: controller.signal,
    };

    // Remove any duplicate '/'
    url = url.replace(/\/+/g, "/");

    if (params) {
      config.params = this.addQueryParameters(url, params);
    }

    if (body !== null) {
      config.data = file ? body : JSON.stringify(body);
    }

    if (headers) {
      config.headers = {
        ...axios.AxiosHeaders,
        ...headers,
      };
    }

    const response = await axios(url, config);

    return response.data;
  };
}
