import BasePage from "../BasePage";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import BackgroundImg from "../../assets/images/background-7.png";
import ButtonComp from "../../components/base_components/ButtonComp";
import PageBackgroundContainer from "../../components/templates/PageBackgroundContainer";
import React from "react";
import { useNavigate } from "react-router-dom";

const LogDisputePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBack = () => {
    navigate(-1);
  };

  const logDisputeClick = () => {
    const url = isMobile
      ? "https://www.vccb.co.za/LogDispute_Mobile"
      : "https://www.vccb.co.za/LogDispute";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <BasePage>
      <PageBackgroundContainer
        handleBack={handleBack}
        backgroundImg={BackgroundImg}
        applyPadding={false}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: { xs: "5rem", sm: 0 },
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <Typography component="h1" variant="pageTitle2" marginBottom={0}>
              Notice an Error on Your Credit Profile?
            </Typography>
            <Typography component="p" variant="body1" marginBottom={4}>
              It's important to correct mistakes on your credit report as soon
              as possible. If you've found incorrect information, you can easily
              log a dispute for free with our trusted credit bureau partner,
              VeriCred Credit Bureau (VCCB). Simply click below to submit your
              dispute and get it resolved within 20 business days.
            </Typography>
            <Grid
              item
              xs={12}
              marginY={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ButtonComp color="secondary" onClick={logDisputeClick}>
                Log a Dispute Now
              </ButtonComp>
            </Grid>
          </Box>
        </Box>
      </PageBackgroundContainer>
    </BasePage>
  );
};

export default LogDisputePage;
