import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import VerifiedIcon from "@mui/icons-material/Verified";
import customTheme from "../constants/Theme";
import { smartCoachModuleStatusEnums } from "../constants/enums";
import { normaliseString } from "./generalHelper";
import BadgeComp from "../components/base_components/BadgeComp";
import LinkComp from "../components/base_components/LinkComp";
import IconListComp from "../components/base_components/IconListComp";

export const getModuleStatusIndex = (status) => {
  return smartCoachModuleStatusEnums.findIndex((s) => s === status);
};

export const getNextModuleStatus = (currentStatus) => {
  const currentStatusIndex = getModuleStatusIndex(currentStatus);

  if (
    currentStatusIndex < 0 ||
    currentStatusIndex >= smartCoachModuleStatusEnums.length - 1
  )
    return currentStatus;

  return smartCoachModuleStatusEnums[currentStatusIndex + 1];
};

export const getIsModuleCompleted = (currentStatus) => {
  const currentStatusIndex = getModuleStatusIndex(currentStatus);

  return currentStatusIndex === smartCoachModuleStatusEnums.length - 1;
};

export const getModuleStatusIcon = (status) => {
  switch (status) {
    case smartCoachModuleStatusEnums[0]: // Not started
      return (
        <CircleOutlinedIcon
          color="secondary"
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              xl: "2.1875rem",
            },
          }}
        />
      );
    case smartCoachModuleStatusEnums[1]: // In progress
      return (
        <CircleOutlinedIcon
          color="brandedTeal"
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              xl: "2.1875rem",
            },
          }}
        />
      );
    case smartCoachModuleStatusEnums[2]: // Completed
      return (
        <CheckCircleIcon
          color="textGood"
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              xl: "2.1875rem",
            },
          }}
        />
      );
    default:
      return null;
  }
};

export const getModuleStatusBadge = (status) => {
  let icon = null;
  let backgroundColor = customTheme.palette.background.default;

  switch (status) {
    case smartCoachModuleStatusEnums[0]: // Not started
      icon = (
        <DoNotDisturbOnIcon
          sx={{
            color: `${customTheme.palette.secondary.main}80`,
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              xl: "2.1875rem",
            },
          }}
        />
      );
      backgroundColor = customTheme.palette.secondary.background;
      break;
    case smartCoachModuleStatusEnums[1]: // In progress
      icon = (
        <CheckCircleIcon
          color="brandedBlue"
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              xl: "2.1875rem",
            },
          }}
        />
      );
      backgroundColor = customTheme.palette.brandedBlue.background;
      break;
    case smartCoachModuleStatusEnums[2]: // Completed
      icon = (
        <CheckCircleIcon
          color="textGood"
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              xl: "2.1875rem",
            },
          }}
        />
      );
      backgroundColor = customTheme.palette.textGood.background;
      break;
    default:
      break;
  }

  return (
    <BadgeComp
      reduceSize
      description={normaliseString(status)}
      icon={icon}
      backgroundColor={backgroundColor}
    />
  );
};

export const renderModuleContent = (moduleContent) => {
  const tagsMapping = [
    {
      openingTag: "<h>",
      closingTag: "</h>",
      mapComponent: (content) => (
        <Typography component="h2" variant="pageTitle2Resp">
          {content}
        </Typography>
      ),
    },
    {
      openingTag: "<sh>",
      closingTag: "</sh>",
      mapComponent: (content) => (
        <Typography component="h3" variant="pageTitle2SmallResp">
          {content}
        </Typography>
      ),
    },
    {
      openingTag: "<p>",
      closingTag: "</p>",
      mapComponent: (content) => (
        <Typography
          component="p"
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: replaceEmphasisTags(content),
          }}
        />
      ),
    },
    {
      openingTag: "<link>",
      closingTag: "</link>",
      mapComponent: (content) => (
        <LinkComp href={getLinkURL(content)}>{getLinkText(content)}</LinkComp>
      ),
    },
    {
      openingTag: "<listBullets>",
      closingTag: "</listBullets>",
      mapComponent: (content) => {
        const listItems = getListItems(content);

        return (
          <IconListComp
            textColor={customTheme.palette.text.primary}
            items={listItems.map((item) => {
              return {
                icon: <VerifiedIcon color="brandedTeal" />,
                text: item,
              };
            })}
          />
        );
      },
    },
    {
      openingTag: "<listNumbered>",
      closingTag: "</listNumbered>",
      mapComponent: (content) => {
        const listItems = getListItems(content);

        return (
          <IconListComp
            items={listItems.map((item, i) => {
              return {
                icon: (
                  <Typography
                    component="p"
                    variant="body1Resp"
                    color="secondary"
                    textAlign="center"
                  >
                    {i})
                  </Typography>
                ),
                text: item,
              };
            })}
          />
        );
      },
    },
  ];

  const replaceEmphasisTags = (content) => {
    return content.replace(/<b>/g, "<strong>").replace(/<\/b>/g, "</strong>");
  };

  const getLinkURL = (content) => {
    const regex = /<linkURL>(.*?)<\/linkURL>/;
    const match = content.match(regex);

    if (match && match.length > 1) {
      return match[1];
    } else {
      return content;
    }
  };

  const getLinkText = (content) => {
    const regex = /<linkText>(.*?)<\/linkText>/;
    const match = content.match(regex);

    if (match && match.length > 1) {
      return match[1];
    } else {
      return content;
    }
  };

  const getListItems = (content) => {
    const regex = /<li>(.*?)<\/li>/g;
    const listItems = [];
    let match;

    while ((match = regex.exec(content)) !== null) {
      listItems.push(match[1]);
    }

    return listItems;
  };

  const componentsArray = [];
  const regex = /<([^>]+)>(.*?)<\/\1>/gs;
  let match;

  while ((match = regex.exec(moduleContent)) !== null) {
    const [fullTag, tagName, content] = match;

    const foundTag = tagsMapping.find(
      (tag) => tag.openingTag === `<${tagName}>`,
    );

    if (foundTag) {
      componentsArray.push(foundTag.mapComponent(content));
    } else {
      // If tag not found in mapping, treat it as plain text
      componentsArray.push(fullTag);
    }
  }

  return (
    <Box>
      {componentsArray.map((comp, c) => (
        <Box key={c} marginBottom={1}>
          {comp}
        </Box>
      ))}
    </Box>
  );
};
