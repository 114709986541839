import { Box, Typography } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const PageBackgroundContainer = ({
  handleBack,
  backgroundImg,
  partial,
  applyPadding,
  ...props
}) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: !applyPadding ? 0 : "1rem 3rem",
      }}
    >
      {/* Back button */}
      {handleBack && (
        <Box
          onClick={handleBack}
          sx={{
            cursor: "pointer",
            display: "flex",
            width: "100%",
            marginTop: 1,
            marginBottom: 3,
          }}
        >
          <ExpandCircleDownIcon
            sx={{ transform: "rotate(90deg)", marginRight: "10px" }}
          />
          <Typography variant="body1" component="span">
            back
          </Typography>
        </Box>
      )}

      {/* Template contents */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
        }}
      >
        {/* Background image */}
        {backgroundImg && (
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              width: {
                xs: "100%",
                md: `calc(80% / ${partial ? 1.3 : 1})`,
                lg: `calc(80% / ${partial ? 2 : 1})`,
                xl: `calc(45% / ${partial ? 2 : 1})`,
              },
              height: "100%",
              position: "relative",
            }}
          >
            <img
              alt="background"
              src={backgroundImg}
              style={{
                width: "calc(50% / 2)",
                position: "fixed",
                bottom: "-2rem",
                right: "-2rem",
              }}
            />
          </Box>
        )}

        {/* Page contents */}
        {props.children}
      </Box>
    </Box>
  );
};

export default PageBackgroundContainer;
