import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { getMonthName } from "../../../utils/generalHelper";
import CreditReportEventComp from "./CreditReportEventComp";

const filterOptions = ["All", "Good", "Bad"];

const MonthSummaryAccordion = ({ scoreEffects, ...props }) => {
  const [filter, setFilter] = useState(filterOptions[0]);
  const [groupedScoreEffects, setGroupedScoreEffects] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (scoreEffects?.length > 0) {
      let grouped = [];

      for (let i = 0; i < scoreEffects.length; i++) {
        const effect = scoreEffects[i];
        const effectDateStr = `${effect.year}${effect.month}`;
        const effectDate = new Date(effect.year, effect.month - 1, 1);

        let group = grouped?.find((g) => g.dateStr === effectDateStr);

        if (!group) {
          grouped.push({
            dateStr: effectDateStr,
            date: effectDate,
            monthName: getMonthName(effect.month),
            effects: [effect],
          });
        } else {
          group.effects.push(effect);
        }
      }

      const sortByDate = (a, b) => (a.date > b.date ? 1 : -1);
      const sortByDateReverse = (a, b) => (a.date < b.date ? 1 : -1);

      setGroupedScoreEffects(
        grouped.sort(isMobile ? sortByDateReverse : sortByDate),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterSelection = (e, selectedFilter) => {
    setFilter(selectedFilter);
  };

  const getFilteredEffects = (effects) => {
    return effects.filter(
      (effect) =>
        filter === "All" ||
        (filter === "Good" && effect.positive) ||
        (filter === "Bad" && !effect.positive),
    );
  };

  if (!scoreEffects || scoreEffects.length === 0) {
    return <></>;
  }

  return (
    <Box sx={props.sx}>
      <Typography component="h2" variant="pageTitle2Small" textAlign="left">
        What Affected Your Score
      </Typography>

      <ToggleButtonGroup
        value={filter}
        onChange={handleFilterSelection}
        exclusive
        sx={{ margin: "1rem 0" }}
      >
        {filterOptions.map((option, i) => (
          <ToggleButton key={i} value={option}>
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <Grid container spacing={2} justifyContent="space-evenly">
        {groupedScoreEffects.map((group, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Accordion defaultExpanded sx={{ width: "98%" }}>
              <AccordionSummary expandIcon={<ExpandCircleDownIcon />}>
                <Typography>{group.monthName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {getFilteredEffects(group.effects).map((item, i) => (
                    <React.Fragment key={i}>
                      {i !== 0 && <ListItem divider />}
                      <CreditReportEventComp
                        item={item}
                      ></CreditReportEventComp>
                    </React.Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MonthSummaryAccordion;
