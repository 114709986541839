import { degreesToRadians } from "./generalHelper";

export const getDoughnutDefaults = () => {
  return {
    cutout: 85,
    startAngle: 225, // The degrees from (0, r) in anti-clockwise direction to where the chart should start
    circumference: 360 - 90, // The full path of the chart
    spacing: -15, // To allow the segments to overlap each other
  };
};

export const getDoughnutCenterCoordinates = (
  fullWidth,
  fullHeight,
  outerRadius,
  circumference,
  startingAngle,
  numSegments,
) => {
  /* The chart is only clipped at the bottom if the starting angle is in the third quadrant and the circumference is not a full circle*/
  let centerX = fullWidth / 2;
  let centerY = fullHeight / 2;

  if (circumference >= 360 || !(startingAngle > 180 && startingAngle < 270)) {
    // Normal scenario - the center y is half the chart's height
    return { xc: centerX, yc: centerY };
  }

  // The chart is clipped at the bottom
  const clippedLength = Math.abs(
    outerRadius * Math.sin(degreesToRadians(circumference / numSegments)),
  );
  centerY = fullHeight - clippedLength;

  return { xc: centerX, yc: centerY };
};

export const getDoughnutWallThickness = (fullWidth, innerRadiusPerc) => {
  return (fullWidth * (100 - innerRadiusPerc)) / 100 / 2;
};
