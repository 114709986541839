import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAuthContext } from "../../contexts/AuthContext";
import { getErrorMessage } from "../../utils/errorHelper";
import { delay } from "../../utils/generalHelper";
import SignUpController from "../../controllers/SignUpController";
import LoginSignupContainer from "../../components/templates/LoginSignupContainer";
import InputComp from "../../components/base_components/InputComp";
import ButtonComp from "../../components/base_components/ButtonComp";
import ErrorMsgComp from "../../components/base_components/ErrorMsgComp";
import LinkComp from "../../components/base_components/LinkComp";
import { FullscreenContext } from "../../contexts/FullscreenContext";

function LoginPage() {
  const { login, logout, abortApiRequests } = useAuthContext();
  const { openFullscreen } = useContext(FullscreenContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const { state } = window.history;

    if (state?.unauthorized === true) {
      window.history.replaceState({}, "", window.location.href);
    }

    return () => {
      abortApiRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsSubmitting(true);
      setErrorMessage("");

      const formData = new FormData(event.currentTarget);
      const credentials = {
        id_number: formData.get("id_number"),
        password: formData.get("password"),
      };

      const responseMsg = await login(credentials);

      if (!responseMsg || responseMsg === "success") {
        const statusResponse = await SignUpController.checkStatus();

        if (!statusResponse.isReportPulled) {
          setErrorMessage(
            statusResponse.errorMsg ||
              "Account status could not be verified. Please try again later.",
          );
          // Reset session token immediately
          logout(false);
        } else if (!statusResponse.isMobileVerified) {
          enqueueSnackbar(
            "Your mobile has not been verified yet, please complete your signup now",
            { variant: "warning" },
          );
          await delay(1500);
          navigate("/sign-up", { state: { verifyMobile: true } });
        } else if (!statusResponse.isAccountVerified) {
          enqueueSnackbar(
            "Your account has not been verified yet, please complete your signup now",
            { variant: "warning" },
          );
          await delay(1500);
          navigate("/sign-up", { state: { verifyAccount: true } });
        } else {
          openFullscreen();
          navigate("/overview");
        }
      } else {
        setErrorMessage(responseMsg);
      }
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const pageTitle = (
    <Typography component="h1" variant="pageTitle1" marginY={4}>
      Login
    </Typography>
  );

  return (
    <LoginSignupContainer pageTitle={pageTitle}>
      {/* Login form components */}
      <Grid container component="form" onSubmit={handleSubmit} noValidate>
        {/* Email input */}
        <Grid item xs={12} marginY={1}>
          <InputComp
            labelText="ID Number"
            type="text"
            id="id_number"
            name="id_number"
            autoFocus
            disabled={isSubmitting}
            inputProps={{ inputMode: "numeric" }}
          />
        </Grid>

        {/* Password input */}
        <Grid item xs={12} marginY={1}>
          <InputComp
            labelText="Password"
            type="password"
            id="password"
            name="password"
            autoComplete="current-password"
            disabled={isSubmitting}
          />
        </Grid>

        {/* Forgot password link */}
        <Grid item xs={12} container justifyContent="flex-end">
          <LinkComp href="/forgot-password" disabled={isSubmitting}>
            Forgot password?
          </LinkComp>
        </Grid>

        {/* Submit button */}
        <Grid
          item
          xs={12}
          marginY={2}
          container={isMobile}
          justifyContent="center"
        >
          <ButtonComp type="submit" isLoading={isSubmitting}>
            Login
          </ButtonComp>
        </Grid>

        {/* Error message */}
        {errorMessage && (
          <Grid item xs={12} container justifyContent="center">
            <ErrorMsgComp errorMessage={errorMessage} />
          </Grid>
        )}

        {/* Signup link */}
        <Grid
          item
          xs={12}
          marginTop={2}
          container={isMobile}
          justifyContent="center"
        >
          <Box component="span" sx={{ display: "flex", width: "fit-content" }}>
            <Typography
              variant="body2"
              component="span"
              sx={{ marginRight: "10px" }}
            >
              Don't have an account?
            </Typography>
            <LinkComp href="/sign-up" disabled={isSubmitting}>
              Sign Up
            </LinkComp>
          </Box>
        </Grid>
      </Grid>
    </LoginSignupContainer>
  );
}

export default LoginPage;
