import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import BasePage from "../BasePage";
import ImageCardComp from "../../components/base_components/ImageCardComp";
import SmartCoachImg from "../../assets/images/card_image_1.png";
import SmartSolutionsImg from "../../assets/images/card_image_2.png";

const SmartHelpPage = (props) => {
  const navigate = useNavigate();

  const goToSmartCoach = () => {
    navigate("/smart-coach");
  };

  const goToSmartSolutions = () => {
    navigate("/smart-solutions");
  };

  return (
    <BasePage>
      <Box width="100%" maxWidth="600px">
        {/* Page title & descriptive text */}
        <Typography component="h1" variant="pageTitle2" marginBottom={2}>
          SmartHelp
        </Typography>

        {/* Page descriptive text */}
        <Typography component="p" variant="body1Resp" marginBottom={5}>
          Your go-to tool to empower you during your financial decision-making
          journey. Gain valuable insights about financial topics and debt or
          insurance related solutions to suit your needs.
        </Typography>
      </Box>

      <Box
        sx={{
          maxWidth: "1500px",
          minHeight: "50%",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          paddingBottom: { xs: "5rem", md: 0 },
          alignItems: { xs: "center", md: "stretch" },
          justifyContent: { xs: "center", md: "space-between" },
          gap: "2rem",
        }}
      >
        <ImageCardComp
          sx={{
            flex: "2 2",
            flexBasis: { xs: "300px", md: "45%" },
            maxWidth: "unset",
          }}
          cardTitle="SmartCoach"
          cardText="Make use of our handy videos, content, and tips while successfully completing topic modules to increase your financial knowledge."
          actionText="Show me"
          actionIcon={<SchoolIcon />}
          handleAction={goToSmartCoach}
          backgroundImg={SmartCoachImg}
        />
        <ImageCardComp
          sx={{
            flex: "2 2",
            flexBasis: { xs: "300px", md: "45%" },
            maxWidth: "unset",
          }}
          cardTitle="SmartSolutions"
          cardText="Find the right financial solutions to meet your needs. Whether you’re seeking a registered debt management programme or exploring affordable short-term and credit life insurance options, our solutions can empower you along your financial journey."
          actionText="Show me"
          actionIcon={<SchoolIcon />}
          handleAction={goToSmartSolutions}
          backgroundImg={SmartSolutionsImg}
        />
      </Box>
    </BasePage>
  );
};

export default SmartHelpPage;
