import { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  getSchemaForOTP,
  validateOtpChars,
} from "../../../utils/validationHelper";
import ButtonComp from "../../../components/base_components/ButtonComp";
import LinkComp from "../../../components/base_components/LinkComp";

const ResetOtpForm = ({ setCompleteOTP, submitCallback, ...props }) => {
  const otpLength = 4;
  const otpSchema = getSchemaForOTP(otpLength);

  const [otp, setOtp] = useState("");
  const [isValidOTP, setIsValidOTP] = useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleComplete = (value) => {
    otpSchema
      .isValid(value)
      .then((valid) => setIsValidOTP(valid))
      .catch((err) => setIsValidOTP(false));
  };

  const handleSubmit = async (event) => {
    if (event.preventDefault) event.preventDefault();

    setCompleteOTP(otp);
    submitCallback();
  };

  return (
    <Container sx={{ padding: "0 !important" }}>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Grid item xs={12}>
          <Typography component="p" variant="body1" textAlign="center">
            We have sent an OTP to your email and mobile we have on record
          </Typography>
        </Grid>

        <Grid item xs={12} marginY={3} container justifyContent="center">
          <MuiOtpInput
            id="otp"
            autoFocus
            length={otpLength}
            value={otp}
            validateChar={validateOtpChars}
            onChange={handleChange}
            onComplete={handleComplete}
            sx={{ maxWidth: "284px" }}
            TextFieldsProps={{
              inputProps: { inputMode: "numeric" },
            }}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <ButtonComp type="submit" disabled={!isValidOTP}>
            Verify
          </ButtonComp>
        </Grid>

        <Grid item xs={12} container justifyContent="center" marginTop={4}>
          <Box component="span" sx={{ display: "flex", width: "fit-content" }}>
            <Typography
              variant="body2"
              component="span"
              sx={{ marginRight: "10px" }}
            >
              I’m not getting my OTP?
            </Typography>
            <LinkComp href="/contact-us">Ask for help</LinkComp>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResetOtpForm;
