import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Divider, Drawer, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useSnackbar } from "notistack";
import customTheme from "../../../../constants/Theme";
import { getErrorMessage } from "../../../../utils/errorHelper";
import { getIsModuleCompleted } from "../../../../utils/smartCoachHelper";
import SmartCoachController from "../../../../controllers/SmartCoachController";
import ButtonComp from "../../../../components/base_components/ButtonComp";
import Topic from "./Topic";
import Module from "./Module";

const CoachContent = ({ renderModule, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoadingTopic, setIsLoadingTopic] = useState(true);
  const [isLoadingModule, setIsLoadingModule] = useState(true);
  const [displayModule, setDisplayModule] = useState(renderModule ?? false);
  const [topic, setTopic] = useState(null);
  const [module, setModule] = useState(null);
  const [nextModule, setNextModule] = useState(null);
  const [selectedModuleId, setSelectedModuleId] = useState(-1);

  useEffect(() => {
    let mounted = true;
    let hasLoadedModule = true;
    const { topicId, moduleNum } = location.state;

    if (!topicId) {
      enqueueSnackbar("No topic selected, redirecting...", {
        variant: "warning",
      });
      navigate("/smart-coach");
      return;
    }

    Promise.allSettled([getTopic(topicId)])
      .then(async (responses) => {
        if (mounted) {
          const resTopic = responses[0].value;

          setTopic(resTopic);

          // Get either the first module or the next module
          if (resTopic?.modules?.length > 0) {
            hasLoadedModule = false;
            if (
              isNaN(moduleNum) ||
              moduleNum < 0 ||
              moduleNum > resTopic.modules.length
            ) {
              assignModule(resTopic.modules[0].module_id);
            } else {
              assignModule(resTopic.modules[moduleNum - 1].module_id);
            }
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setIsLoadingTopic(false);
          if (hasLoadedModule) setIsLoadingModule(false);
        }
      });

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!topic || !module) return;

    setNextModule(
      topic.modules?.find((m) => m.module_number === module.module_number + 1),
    );
    setSelectedModuleId(module.module_id);
  }, [topic, module]);

  const getTopic = (topicId) => {
    return new Promise(async (res, rej) => {
      try {
        const topicResponse =
          await SmartCoachController.getTopicDetails(topicId);

        if (!topicResponse || Object.keys(topicResponse).length <= 0) {
          enqueueSnackbar(
            "Failed to retrieve the coaching topic details from the server. Please try again later",
            { variant: "error" },
          );
          rej();
          return;
        }

        res(topicResponse);
      } catch (error) {
        const errMsg = getErrorMessage(error);

        // Check if request was aborted
        if (errMsg === null) return;

        enqueueSnackbar(errMsg, { variant: "error" });
        rej(errMsg);
      }
    });
  };

  const getModule = (moduleId) => {
    return new Promise(async (res, rej) => {
      if (!moduleId || isNaN(moduleId)) {
        rej("No valid module ID provided");
        return;
      }

      try {
        const moduleResponse =
          await SmartCoachController.getModuleDetails(moduleId);

        if (!moduleResponse || Object.keys(moduleResponse).length <= 0) {
          enqueueSnackbar(
            "Failed to retrieve the coaching module details from the server. Please try again later",
            { variant: "error" },
          );
          rej();
          return;
        }

        res(moduleResponse);
      } catch (error) {
        const errMsg = getErrorMessage(error);

        // Check if request was aborted
        if (errMsg === null) return;

        enqueueSnackbar(errMsg, { variant: "error" });
        rej(errMsg);
      }
    });
  };

  const assignModule = (moduleId, selectMobileDisplay) => {
    setIsLoadingModule(true);

    Promise.allSettled([getModule(moduleId)])
      .then(async (responses) => {
        setModule(responses[0].value);
      })
      .finally(() => {
        setIsLoadingModule(false);
        setDisplayModule((renderModule && true) || selectMobileDisplay);
      });
  };

  const saveModule = async (completeModule, ignoreNavigation) => {
    try {
      if (!getIsModuleCompleted(module.status)) {
        let resp = null;

        if (completeModule) {
          resp = await SmartCoachController.completeModule(module.module_id);
        } else {
          resp = await SmartCoachController.saveModule(module.module_id);
        }

        if (resp?.status && resp.status !== module.status) {
          const tempModule = {
            ...module,
            status: resp.status,
          };

          const tempTopic = {
            ...topic,
            modules: topic.modules.map((m) =>
              m.module_id === tempModule.module_id ? tempModule : m,
            ),
          };

          setModule(tempModule);
          setTopic(tempTopic);
        }

        enqueueSnackbar("Module progress updated", { variant: "info" });
      }
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
      return false;
    }

    if (!ignoreNavigation) navigate(-1);

    return true;
  };

  const saveProgress = () => {
    saveModule();
  };

  const continueModule = async () => {
    const saveResult = await saveModule(true, true);

    if (!saveResult) return;

    if (Object.keys(nextModule || {}).length > 0) {
      selectModule(nextModule.module_id);
    } else {
      navigate("/smart-coach/completed", {
        state: { completedTopicTitle: topic.title },
      });
    }
  };

  const selectModule = (moduleId) => {
    assignModule(moduleId, true);
  };

  const isModuleDisabled = (moduleIndex) => {
    return (
      moduleIndex > 0 && topic?.modules[moduleIndex - 1].status !== "completed"
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  const nextModuleSection = (
    <Box
      width="100%"
      sx={
        isMobile
          ? {}
          : {
              position: "sticky",
              bottom: "1rem",
              background: theme.palette.background.default,
            }
      }
    >
      <Divider />
      <Box
        paddingY={2}
        sx={
          isMobile
            ? {}
            : {
                background: `${theme.palette.secondary.main}1a`,
              }
        }
      >
        {!isLoadingModule && (
          <>
            {Object.keys(nextModule || {}).length > 0 && (
              <>
                <Typography
                  component="h6"
                  variant="pageTitle2SmallResp"
                  sx={{
                    color: customTheme.palette.textVeryGood.main,
                  }}
                  gutterBottom
                >
                  Next up:
                </Typography>
                <Typography component="p" variant="body1Resp" gutterBottom>
                  Module {nextModule.module_number}: {nextModule.title}
                </Typography>
              </>
            )}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "2rem",
                gap: "1rem",
              }}
            >
              <ButtonComp color="secondary" onClick={saveProgress}>
                Save
              </ButtonComp>
              <ButtonComp onClick={continueModule}>Continue</ButtonComp>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );

  const topicDrawer = (
    <Drawer
      variant="persistent"
      anchor="left"
      open={true}
      transitionDuration={1000}
      PaperProps={{
        sx: { width: "30%", minWidth: { xs: "250px", md: "350px" } },
      }}
    >
      <Box padding={2}>
        <Topic
          topic={topic}
          isLoading={isLoadingTopic}
          selectedModuleId={selectedModuleId}
          selectModule={selectModule}
          isModuleDisabled={isModuleDisabled}
        />
        {nextModuleSection}
      </Box>
    </Drawer>
  );

  return !isMobile ? (
    /* Desktop devices - render both topic & module content */
    <Box
      sx={{
        height: "100vh",
        float: "right",
        width: {
          xs: "100%",
          sm: "calc(100% - max(30%, 250px))",
          md: "calc(100% - max(30%, 350px))",
        },
      }}
    >
      {/* Display topic in a drawer */}
      {topicDrawer}

      {/* Display module in the main window */}
      <Module
        topic={topic}
        module={module}
        nextModule={nextModule}
        isLoading={isLoadingModule}
        saveModule={saveProgress}
        continueModule={continueModule}
        hideNextModule
      />
    </Box>
  ) : /* Mobile devices - render only the topic or module content */
  displayModule ? (
    <Module
      topic={topic}
      module={module}
      nextModule={nextModule}
      isLoading={isLoadingModule}
      saveModule={saveProgress}
      continueModule={continueModule}
      stickyVideo
    />
  ) : (
    <Box p={2}>
      {/* Back button */}
      <Box
        onClick={handleBack}
        sx={{
          cursor: "pointer",
          display: "flex",
          width: "fit-content",
          marginBottom: { xs: "2rem", sm: "4rem" },
        }}
      >
        <ExpandCircleDownIcon
          sx={{ transform: "rotate(90deg)", marginRight: "10px" }}
        />
        <Typography variant="body1" component="span">
          back
        </Typography>
      </Box>

      <Topic
        topic={topic}
        isLoading={isLoadingTopic}
        selectedModuleId={selectedModuleId}
        selectModule={selectModule}
        isModuleDisabled={isModuleDisabled}
      />
    </Box>
  );
};

export default CoachContent;
