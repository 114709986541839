import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import BasePage from "../BasePage";
import PageBackgroundContainer from "../../components/templates/PageBackgroundContainer";
import CreditProfileController from "../../controllers/CreditProfileController";
import AccountsTab from "./components/AccountsTab";
import PaymentHistoryTab from "./components/PaymentHistoryTab";
import OtherListingsTab from "./components/OtherListingsTab";
import BackgroundRedImg from "../../assets/images/background_red.png";
import BackgroundTealImg from "../../assets/images/background_teal.png";
import customTheme from "../../constants/Theme";

export const ACCOUNTS_TAB = "accounts";
export const PAYMENT_HISTORY_TAB = "payment-history";
export const OTHER_LISTINGS_TAB = "other-listings";

const AccountsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [closedAccounts, setClosedAccounts] = useState([]);
  const [otherListings, setOtherListings] = useState([]);
  const [selectedTab, setSelectedTab] = useState(ACCOUNTS_TAB);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    Promise.allSettled([
      CreditProfileController.getAccounts(),
      CreditProfileController.getOtherListings(),
    ]).then((results) => {
      setAccounts(results[0].value);
      setOtherListings(results[1].value);
      setClosedAccounts(
        results[1].value?.closed_paid_up_accounts?.filter(
          (account) => account.account_status !== "Paid Up",
        ),
      );
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (location.state === OTHER_LISTINGS_TAB) {
      setSelectedTab(OTHER_LISTINGS_TAB);
    } else if (location.state === PAYMENT_HISTORY_TAB) {
      setSelectedTab(PAYMENT_HISTORY_TAB);
    } else {
      setSelectedTab(ACCOUNTS_TAB);
    }
  }, [location]);

  /**
   * Calculates borders for accordion object in list.
   *
   * @param i
   * @param listLength
   * @returns {{boxShadow: string, borderRadius: (string), width: string}}
   */
  const accordionSx = (i, listLength) => {
    return {
      width: "100%",
      borderRadius:
        i === 0 && i === listLength - 1
          ? "1rem !important"
          : i === 0
            ? "1rem 1rem 0 0 !important"
            : i === listLength - 1
              ? "0 0 1rem 1rem !important"
              : "0 !important",
      boxShadow: `0 2px 1px -1px #0003, 0 1px 1px 0 ${customTheme.palette.secondary.main}40, 0 1px 3px 0 #0003`,
    };
  };

  const getBackgroundImage = () => {
    if (selectedTab === ACCOUNTS_TAB) return BackgroundRedImg;
    if (selectedTab === PAYMENT_HISTORY_TAB) return BackgroundTealImg;
    return null;
  };

  const hasBackgroundImg = () => {
    return !!getBackgroundImage();
  };

  return (
    <BasePage>
      <PageBackgroundContainer
        backgroundImg={getBackgroundImage()}
        partial={hasBackgroundImg()}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: { xs: "5rem", sm: 0 },
          }}
        >
          {/* Page title */}
          <Box width="100%" maxWidth={hasBackgroundImg() ? "800px" : "1100px"}>
            <Typography component="h1" variant="pageTitle2" marginBottom={5}>
              My Accounts
            </Typography>
          </Box>

          {isLoading && (
            <Box
              sx={{
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="pageTitle1" marginBottom={5}>
                Loading Your Information
              </Typography>
              <CircularProgress size="5rem" />
            </Box>
          )}

          {!isLoading && (
            <Box
              sx={{
                width: "100%",
                maxWidth: hasBackgroundImg() ? "800px" : "1100px",
              }}
            >
              {/* Toggle buttons */}
              <ToggleButtonGroup
                value={selectedTab}
                onChange={(e, value) => navigate("/accounts", { state: value })}
                exclusive
                sx={{ margin: "1rem 0" }}
              >
                <ToggleButton value="accounts">ACCOUNTS</ToggleButton>
                <ToggleButton value="payment-history">
                  PAYMENT HISTORY
                </ToggleButton>
                <ToggleButton value="other-listings">
                  OTHER LISTINGS
                </ToggleButton>
              </ToggleButtonGroup>

              {selectedTab === ACCOUNTS_TAB && (
                <AccountsTab
                  accounts={accounts}
                  closedAccounts={closedAccounts}
                  accordionSx={accordionSx}
                />
              )}

              {selectedTab === PAYMENT_HISTORY_TAB && (
                <PaymentHistoryTab accounts={accounts} />
              )}

              {selectedTab === OTHER_LISTINGS_TAB && (
                <OtherListingsTab
                  otherListings={otherListings}
                  accordionSx={accordionSx}
                />
              )}
            </Box>
          )}
        </Box>
      </PageBackgroundContainer>
    </BasePage>
  );
};

export default AccountsPage;
