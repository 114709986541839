import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ButtonComp from "../base_components/ButtonComp";

const SimplePopup = ({
  titleComp,
  contentComp,
  actionText,
  showPopup,
  handleClose,
}) => {
  return (
    <Dialog
      open={showPopup}
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { width: "30rem", padding: "20px" } }}
    >
      {titleComp && <DialogTitle>{titleComp}</DialogTitle>}
      {contentComp && <DialogContent>{contentComp}</DialogContent>}
      <DialogActions
        sx={{
          padding: "10px 20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonComp
          onClick={handleClose}
          sx={{
            padding: "0.75rem 1rem",
            border: "1px solid black",
            borderRadius: "5rem",
            fontSize: "1rem",
            fontWeigth: 400,
            lineHeight: "normal",
            color: "black",
            background: "transparent",
          }}
        >
          {actionText}
        </ButtonComp>
      </DialogActions>
    </Dialog>
  );
};

export default SimplePopup;
