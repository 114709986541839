import axios from "axios";

export function setBaseURL(baseURL) {
  axios.defaults.baseURL = baseURL;
}

export function setContentTypeHeader(type) {
  axios.defaults.headers.post["Content-Type"] = type;
}

export function clearAuthHeader() {
  axios.interceptors.request.clear();
}

export function setAuthHeader(token) {
  axios.interceptors.request.use(
    async (config) => {
      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );
}

export function setUnauthorizedResponse(callback) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;

      if (
        error?.response?.status === 401 &&
        !originalRequest.url.includes("/login")
      ) {
        if (callback) callback();
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
  );
}
