import React from "react";
import { Box } from "@mui/material";

const CarouselItemComp = ({ item, ...props }) => {
  return (
    <Box mx={1} sx={{ aspectRatio: 1 }}>
      {item}
    </Box>
  );
};

export default CarouselItemComp;
