import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useAuthContext } from "../../contexts/AuthContext";
import * as Schemas from "../../utils/validationHelper";
import { getErrorMessage } from "../../utils/errorHelper";
import BasePage from "../BasePage";
import InputComp from "../../components/base_components/InputComp";
import ButtonComp from "../../components/base_components/ButtonComp";
import ErrorMsgComp from "../../components/base_components/ErrorMsgComp";
import LinkComp from "../../components/base_components/LinkComp";
import PageBackgroundContainer from "../../components/templates/PageBackgroundContainer";
import BackgroundImg from "../../assets/images/background-7.png";
import ContactUsController from "../../controllers/ContactUsController";

const ContactUsPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [errorMessage, setErrorMessage] = useState("");
  const messageCompRef = useRef(null);

  const initValues = {
    name: "",
    mobile: "",
    email: "",
    message: "",
  };

  const validations = Yup.object({
    name: Schemas.getSchemaForRequiredText(),
    mobile: Schemas.getSchemaForTelNumber(true),
    email: Schemas.getSchemaEmail(true),
    message: Schemas.getSchemaForRequiredText(),
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (values, actions) => {
    try {
      setErrorMessage("");

      /* API call and error handling */
      const responseMsg = await ContactUsController.postContactUsForm(values);

      if (!responseMsg?.message || responseMsg?.message === "Success") {
        enqueueSnackbar(
          "Your message has been sent. We will get back to you as soon as possible",
          { variant: "info" },
        );
      } else {
        setErrorMessage(responseMsg?.message);
      }

      actions.resetForm();

      if (messageCompRef.current) messageCompRef.current.resetNumChars();
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
    }
  };

  return (
    <BasePage ignoreAuthentication>
      <PageBackgroundContainer
        handleBack={handleBack}
        backgroundImg={BackgroundImg}
        applyPadding={!isAuthenticated}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: { xs: "5rem", sm: 0 },
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <Typography component="h1" variant="pageTitle2" marginBottom={0}>
              Contact Support
            </Typography>
            <Typography component="p" variant="body1" marginBottom={2}>
              Send us a message so we can get you access to your account as soon
              as possible.
            </Typography>

            <Formik
              initialValues={initValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
              validateOnBlur={false}
            >
              {({
                values,
                isSubmitting,
                touched,
                errors,
                handleChange,
                handleBlur,
              }) => (
                <Form noValidate>
                  <Grid container>
                    {/* Name input */}
                    <Grid item xs={12} marginY={1}>
                      <InputComp
                        labelText="Name"
                        type="text"
                        id="name"
                        autoFocus
                        required
                        value={values.name}
                        disabled={isSubmitting}
                        touched={touched}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Mobile input */}
                    <Grid item xs={12} marginY={1}>
                      <InputComp
                        labelText="Mobile number"
                        type="text"
                        id="mobile"
                        required
                        value={values.mobile}
                        disabled={isSubmitting}
                        touched={touched}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Email input */}
                    <Grid item xs={12} marginY={1}>
                      <InputComp
                        labelText="Email"
                        type="email"
                        id="email"
                        required
                        value={values.email}
                        disabled={isSubmitting}
                        touched={touched}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Message input */}
                    <Grid item xs={12} marginY={1}>
                      <InputComp
                        ref={messageCompRef}
                        labelText="Message"
                        type="text"
                        id="message"
                        required
                        placeholder="Start typing here..."
                        multiline
                        minRows={5}
                        inputProps={{ maxLength: 160 }}
                        value={values.message}
                        disabled={isSubmitting}
                        touched={touched}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Support T&Cs link */}
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent="flex-end"
                      my={1}
                    >
                      <LinkComp
                        href="/legal/support"
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={isSubmitting}
                      >
                        View Support Terms and Conditions
                      </LinkComp>
                    </Grid>

                    {/* Submit button */}
                    <Grid
                      item
                      xs={12}
                      marginY={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ButtonComp type="submit" isLoading={isSubmitting}>
                        Send
                      </ButtonComp>
                    </Grid>

                    {/* Error message */}
                    {errorMessage && (
                      <Grid item xs={12} container justifyContent="center">
                        <ErrorMsgComp errorMessage={errorMessage} />
                      </Grid>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </PageBackgroundContainer>
    </BasePage>
  );
};

export default ContactUsPage;
