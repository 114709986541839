import { Box, Container, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as Schemas from "../../../utils/validationHelper";
import InputComp from "../../../components/base_components/InputComp";
import ButtonComp from "../../../components/base_components/ButtonComp";
import LinkComp from "../../../components/base_components/LinkComp";

const InitialForm = ({
  initialFormValues,
  setFormValues,
  submitCallback,
  ...props
}) => {
  const initValues = {
    email: initialFormValues?.email || "",
    mobile: initialFormValues?.mobile || "",
    mobileConfirmation: initialFormValues?.mobile || "",
  };

  const validations = Yup.object({
    email: Schemas.getSchemaEmail(true),
    mobile: Schemas.getSchemaForTelNumber(true),
    mobileConfirmation: Schemas.getSchemaForConfirmTelNumber("mobile"),
  });

  const handleSubmit = (values, actions) => {
    setFormValues({
      ...initialFormValues,
      email: values.email,
      mobile: values.mobile,
    });

    if (submitCallback) submitCallback();

    actions.setSubmitting(false);
  };

  return (
    /* Signup form components */
    <Container sx={{ padding: "0 !important" }}>
      <Formik
        initialValues={initValues}
        validationSchema={validations}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({
          values,
          isSubmitting,
          touched,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <Form noValidate>
            <Grid item xs={12} container>
              {/* Email input */}
              <Grid item xs={12} marginY={1}>
                <InputComp
                  labelText="Let's begin with your email"
                  type="email"
                  id="email"
                  required
                  autoFocus
                  autoComplete="email"
                  value={values.email}
                  disabled={isSubmitting}
                  touched={touched}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {/* Mobile number input */}
              <Grid item xs={12} marginY={1}>
                <InputComp
                  labelText="Your mobile number"
                  type="tel"
                  id="mobile"
                  required
                  autoComplete="tel"
                  value={values.mobile}
                  disabled={isSubmitting}
                  touched={touched}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {/* Confirm mobile number input */}
              <Grid item xs={12} marginY={1}>
                <InputComp
                  labelText="Confirm your mobile number"
                  type="tel"
                  id="mobileConfirmation"
                  required
                  autoComplete="tel"
                  value={values.mobileConfirmation}
                  disabled={isSubmitting}
                  touched={touched}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {/* Submit button */}
              <Grid item xs={12} marginY={4}>
                <ButtonComp type="submit" isLoading={isSubmitting}>
                  Create my account
                </ButtonComp>
              </Grid>

              {/* Login page link */}
              <Grid item xs={12} marginTop={2}>
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ marginRight: "10px" }}
                  >
                    Already have an account?
                  </Typography>
                  <LinkComp href="/login" disabled={isSubmitting}>
                    Login
                  </LinkComp>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default InitialForm;
