import { Grid, Tooltip, Typography } from "@mui/material";
import { getMonthName } from "../../../utils/generalHelper";
import customTheme from "../../../constants/Theme";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PaymentHistoryGrid = ({ account, compact = false }) => {
  if (!account.payment_history || account.payment_history.length <= 0) {
    return (
      <Grid item xs={12} container justifyContent="center">
        <Typography component="p" variant="pageBodyBold">
          No history available
        </Typography>
      </Grid>
    );
  }

  let result = [];
  let start = Math.max(0, account.payment_history.length - 7);
  let end = Math.max(0, account.payment_history.length - 1);

  for (let i = start; i < end; i++) {
    const payment = account.payment_history[i];

    payment.monthName = getMonthName(payment.month, false);
    payment.icon =
      payment.months_in_arrears < 0 ? (
        <Tooltip title="No Data">
          <Typography
            sx={{
              color: `${customTheme.palette.secondary.main}80`,
            }}
          >
            -
          </Typography>
        </Tooltip>
      ) : payment.months_in_arrears > 0 ? (
        <Tooltip title="In Arrears">
          <CancelIcon sx={{ color: customTheme.palette.primary.main }} />
        </Tooltip>
      ) : (
        <Tooltip title="Up to Date">
          <CheckCircleIcon
            sx={{
              color: customTheme.palette.chartColorVeryGood.main,
            }}
          />
        </Tooltip>
      );

    result.push(payment);
  }

  return result.map((payment, i) => (
    <Grid
      key={i}
      item
      xs={compact ? 2 : 1}
      md={compact ? 4 : 2}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography component="p" variant="pageBodyBoldResp">
        {payment.monthName}
      </Typography>
      {payment.icon}
    </Grid>
  ));
};

export default PaymentHistoryGrid;
