import BaseController from "./BaseController";

class Controller extends BaseController {
  constructor() {
    super();
    this.ProductEnums = [
      "debt_review",
      "credit_cover",
      "vehicle_insurance",
      "building_insurance",
      "home_content_cover",
    ];
    this.SolutionsURL = "/smart-solutions";
    this.CallToActionURL = "/smart-solutions/call-to-action";
  }

  getSolutions = () => {
    return this.sendRequest("GET", this.SolutionsURL);
  };

  submitCallToAction = (productEnum) => {
    const requestBody = {
      product: productEnum,
    };

    return this.sendRequest("POST", this.CallToActionURL, null, requestBody);
  };
}

const SmartSolutionsController = new Controller();

export default SmartSolutionsController;
