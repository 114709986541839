import { Container, Grid, Typography } from "@mui/material";
import InputComp from "../../../components/base_components/InputComp";
import { Form, Formik } from "formik";
import ButtonComp from "../../../components/base_components/ButtonComp";
import * as Yup from "yup";
import * as Schemas from "../../../utils/validationHelper";

const ResendOtpForm = ({
  currentMobile = "",
  handleSendOtp,
  handleResendOtp,
}) => {
  const initValues = {
    mobile: currentMobile || "",
  };

  const validations = Yup.object({
    mobile: Schemas.getSchemaForTelNumber(true),
  });

  const handleSubmit = (values, actions) => {
    if (values.mobile === currentMobile) {
      handleResendOtp().then((_) => {
        actions.setSubmitting(false);
      });
    } else {
      handleSendOtp(values.mobile).then((_) => {
        actions.setSubmitting(false);
      });
    }
  };

  return (
    <Container sx={{ padding: "0 !important" }}>
      <Typography component="p" variant="body1" marginBottom={2}>
        Please confirm your mobile number for OTP resend
      </Typography>

      <Formik
        initialValues={initValues}
        validationSchema={validations}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({
          values,
          isSubmitting,
          touched,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <Form noValidate>
            <Grid item xs={12} container>
              {/* Mobile number input */}
              <Grid item xs={12} marginY={1}>
                <InputComp
                  labelText="Your mobile number"
                  type="tel"
                  id="mobile"
                  required
                  autoComplete="tel"
                  value={values.mobile}
                  disabled={isSubmitting}
                  touched={touched}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {/* Submit button */}
              <Grid item xs={12} marginY={4}>
                <ButtonComp type="submit" isLoading={isSubmitting}>
                  Send OTP
                </ButtonComp>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ResendOtpForm;
