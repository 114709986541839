import * as React from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import SimplePopup from "../../../components/templates/SimplePopup";
import customTheme from "../../../constants/Theme";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function CreditReportEventComp({ item }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* List Item */}
      <ListItem
        disablePadding
        secondaryAction={
          <IconButton
            onClick={handleClickOpen}
            edge="end"
            sx={{
              color: customTheme.palette.chartColorNeutral.main,
              marginRight: "-1.25rem",
            }}
          >
            <VisibilityIcon />
          </IconButton>
        }
      >
        <ListItemIcon>
          <Box
            sx={{
              color: item.positive
                ? customTheme.palette.brandedTeal.main
                : customTheme.palette.primary.main,
              fontSize: "1.5rem",
            }}
          >
            <i
              className={
                item.positive
                  ? "bi bi-arrow-up-circle"
                  : "bi bi-arrow-down-circle"
              }
            ></i>
          </Box>
        </ListItemIcon>
        <ListItemText
          sx={{
            paddingRight: "2.2rem",
          }}
          primary={
            <React.Fragment>
              <Grid
                container
                rowSpacing={0}
                columnSpacing={2}
                sx={{ alignItems: "center" }}
              >
                <Grid item xs={4}>
                  <Typography>{item.name}</Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Typography variant="body1Bold">
                    {item.description}
                  </Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          }
        ></ListItemText>
      </ListItem>

      {/* Popup Dialog */}
      <SimplePopup
        showPopup={open}
        handleClose={handleClose}
        titleComp={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "4.6875rem",
              background: item.positive
                ? customTheme.palette.brandedTeal.background
                : customTheme.palette.primary.background,
            }}
          >
            <Box
              sx={{
                color: item.positive
                  ? customTheme.palette.brandedTeal.main
                  : customTheme.palette.primary.main,
                fontSize: "1.5rem",
                margin: "0.5rem",
              }}
            >
              <i
                className={
                  item.positive
                    ? "bi bi-arrow-up-circle"
                    : "bi bi-arrow-down-circle"
                }
              ></i>
            </Box>
            <Typography
              component="h5"
              variant="popupTitle"
              sx={{
                color: item.positive
                  ? customTheme.palette.brandedTeal.main
                  : customTheme.palette.primary.main,
              }}
            >
              {item.name}
            </Typography>
          </Box>
        }
        contentComp={
          <>
            <Typography component="h6" variant="popupContent">
              {item.long_description}
            </Typography>
            <Typography
              component="h6"
              variant="popupContent"
              sx={{ paddingTop: "0.5rem" }}
            >
              <b>Handy Tip:</b> {item.handy_tip}
            </Typography>
          </>
        }
        actionText="Close"
      />
    </React.Fragment>
  );
}
