import { Box, Card, Divider, Grid, Paper, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import VerifiedIcon from "@mui/icons-material/Verified";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import customTheme from "../../../../constants/Theme";
import { currencyFormatter } from "../../../../utils/generalHelper";
import ButtonComp from "../../../../components/base_components/ButtonComp";
import LinkComp from "../../../../components/base_components/LinkComp";
import IconListComp from "../../../../components/base_components/IconListComp";
import ImageCardComp from "../../../../components/base_components/ImageCardComp";
import BenefitsImg1 from "../../../../assets/images/card_image_3.jpg";
import BenefitsImg2 from "../../../../assets/images/card_image_4.jpg";
import BenefitsImg3 from "../../../../assets/images/card_image_5.jpg";
import BenefitsImg4 from "../../../../assets/images/card_image_6.jpg";
import BenefitsImg5 from "../../../../assets/images/card_image_7.jpg";
import BenefitsImg6 from "../../../../assets/images/card_image_8.jpg";
import CarouselContainer from "../../../../components/base_components/CarouselContainer";

const benefits = [
  {
    title: "Retrenchment Cover",
    description:
      "If you get retrenched, we have you covered by paying your monthly debit instalments for up to 12 months.",
    backgroundImg: BenefitsImg1,
  },
  {
    title: "Death Cover",
    description: "In the event of your death, we'll settle your debt.",
    backgroundImg: BenefitsImg2,
  },
  {
    title: "Maternity Cover",
    description:
      "Should you have an unplanned pregnancy, we'll cover your debt instalments during your maternity leave period for up to 3 months.",
    backgroundImg: BenefitsImg3,
  },
  {
    title: "Critical Illness@ Permanent Disability Cover",
    description:
      "Should you become critically ill or permanently disabled, we'll settle your debt.",
    backgroundImg: BenefitsImg4,
  },
  {
    title: "Temporary Disability Cover",
    description: "We'll pay your debt instalments for up to 12 months.",
    backgroundImg: BenefitsImg5,
  },
  {
    title: "Identity Theft Cover",
    description:
      "It happens, but we've got you covered with a lumpsum to help you regain control.",
    backgroundImg: BenefitsImg6,
  },
];

const CreditCoverTab = ({ callToAction, details, ...props }) => {
  return (
    <Box>
      {/* Heading & call to action */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "2rem 0",
        }}
      >
        <Typography component="h2" variant="pageTitle2Resp" mr={1}>
          Cover Your Credit
        </Typography>
        <ButtonComp
          onClick={() => {
            callToAction("credit_cover");
          }}
          sx={{
            minWidth: "120px",
            width: "40%",
            display: { xs: "none", md: "inline-flex" },
          }}
        >
          Call me
        </ButtonComp>
      </Box>

      {/* Tab content conditionally split into two columns */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "flex-start",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", md: "space-between" },
          gap: "1rem",
        }}
      >
        {/* Highlights, premium reductions & info bubble */}
        <Box
          sx={{
            flex: "1 1 60%",
            maxWidth: { xs: "100%", md: "1024px" },
          }}
        >
          {/* Bulleted highlights */}
          <Typography component="p" variant="body1Resp" sx={{ color: "white" }}>
            Cover your credit with CreditCover<sup>TM</sup>. ONE policy to
            replace them all!
          </Typography>
          <Box>
            <IconListComp
              textColor={customTheme.palette.text.primary}
              items={[
                {
                  icon: <VerifiedIcon color="brandedTeal" />,
                  text: "NO medical assessment required.",
                },
                {
                  icon: <VerifiedIcon color="brandedTeal" />,
                  text: "Easy claims process & competitive rates to SAVE you MONEY.",
                },
              ]}
            />
          </Box>

          {/* Reduced premiums table */}
          <Card
            sx={{
              marginY: "1rem",
              borderRadius: "1rem",
              backgroundColor: `${customTheme.palette.brandedTeal.main}0d`,
              padding: "1rem 0.5rem",
              width: "100%",
            }}
          >
            <Grid container spacing={1} alignItems="center">
              {/* Header row */}
              <Grid item xs={7} sm={6}>
                <Typography component="p" variant="body1BoldResp">
                  Estimated current premium
                </Typography>
              </Grid>
              <Grid item xs={5} sm={6} container justifyContent="end">
                <Typography
                  component="p"
                  variant="body1BoldResp"
                  textAlign="end"
                >
                  Adjusted premium
                </Typography>
              </Grid>

              {/* Premiums row */}
              <Grid item xs={7} sm={6}>
                <Typography component="p" variant="body1Resp">
                  {currencyFormatter.format(
                    details?.estimated_current_premium || 0,
                  )}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={6} container justifyContent="end">
                <Typography
                  component="p"
                  variant="body1BoldResp"
                  textAlign="end"
                >
                  {currencyFormatter.format(details?.adjusted_premium || 0)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider
                  sx={{
                    margin: "1rem 0",
                    borderColor: customTheme.palette.brandedBlue.main,
                  }}
                />
              </Grid>

              {/* Totals row */}
              <Grid item xs={9}>
                <Typography component="p" variant="body1Resp">
                  Monthly saving on premium:
                </Typography>
              </Grid>
              <Grid item xs={3} container justifyContent="end">
                <Typography
                  component="p"
                  variant="body1BoldResp"
                  textAlign="end"
                >
                  {currencyFormatter.format(
                    Math.abs(
                      details?.estimated_current_premium -
                        details?.adjusted_premium,
                    ) || 0,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>

          {/* Information bubble */}
          <Box width="100%" marginY={2}>
            <Paper className="infoBubble">
              <StarIcon color="brandedBlue" />
              <Typography component="span" variant="body1Resp" ml={1}>
                With the CreditCover<sup>TM</sup> product, you'll pay{" "}
                {details?.percentage_reduction}% less on your current estimated
                premium!
              </Typography>
            </Paper>
          </Box>
        </Box>

        {/* Did you know card */}
        <Box
          sx={{
            flex: "1 1",
            minWidth: { xs: "unset", md: "275px", lg: "400px" },
            maxWidth: "600px",
          }}
        >
          <Box width="100%">
            <Paper
              className="infoBubble"
              sx={{
                flexDirection: "column",
                padding: "1rem !important",
              }}
            >
              <Box display="flex" alignItems="center">
                <TipsAndUpdatesIcon fontSize="medium" />
                <Typography
                  component="span"
                  variant="pageTitle2SmallResp"
                  ml={1}
                >
                  Did you know?
                </Typography>
              </Box>

              <Typography component="p" variant="body1Resp" paddingY={2}>
                You don't have to use your bank's provided credit life cover.
                THINK quality, simple & affordable all in ONE GO!
              </Typography>

              <Typography
                component="p"
                variant="body1BoldResp"
                sx={{
                  color: customTheme.palette.textVeryGood.main,
                }}
              >
                Why wait?
              </Typography>
            </Paper>
          </Box>
        </Box>
      </Box>

      {/* Benefits carousel */}
      <Typography component="h6" variant="pageTitle2Small" mt={4} mb={2}>
        CreditCover<sup>TM</sup> Benefits
      </Typography>
      <Box>
        <CarouselContainer
          items={benefits.map((benefit, i) => (
            <ImageCardComp
              cardTitle={benefit.title}
              cardText={benefit.description}
              backgroundImg={benefit.backgroundImg}
              darkenBackground
              sx={{
                height: "100%",
                maxWidth: "unset",
                cursor: "move",
              }}
            />
          ))}
        />
      </Box>

      {/* Call to action for mobile screens */}
      <Box
        sx={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonComp
          onClick={() => {
            callToAction("credit_cover");
          }}
          sx={{
            width: "200px",
            display: { xs: "inline-flex", md: "none" },
          }}
        >
          Call me
        </ButtonComp>
      </Box>

      <Divider sx={{ margin: "1rem 0" }} />

      {/* Tab T&C's */}
      <Box
        mt={4}
        component="span"
        sx={{ display: "flex", width: "fit-content" }}
      >
        <Typography variant="body2" component="span">
          By clicking the Call Me button you agree to the
          <LinkComp
            href="/legal/credit-cover"
            sx={{ marginLeft: "5px" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </LinkComp>
        </Typography>
      </Box>
    </Box>
  );
};

export default CreditCoverTab;
