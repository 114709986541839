import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import customTheme from "./Theme";

export const appTitle = "ThreeSixty.me";

export const creditScoreRatingData = [
  {
    title: "Your Journey Starts Now",
    ratingLowerVal: 0,
    ratingUpperVal: 2,
    ratingVal: 0,
    icon: (
      <DoNotDisturbOnIcon
        sx={{ color: `${customTheme.palette.secondary.main}80` }}
      />
    ),
    iconResp: (
      <DoNotDisturbOnIcon
        sx={{
          color: `${customTheme.palette.secondary.main}80`,
          fontSize: { xs: "1.25rem", sm: "1.5rem", xl: "2.1875rem" },
        }}
      />
    ),
    backgroundColor: customTheme.palette.secondary.background,
    descriptionShort: "No score",
    descriptionFull:
      "There is not enough information available to evaluate your credit worthiness. This might happen if you are new to credit or have not used credit in a while.",
  },
  {
    title: "Stay Strong, Start Fresh",
    ratingLowerVal: 3,
    ratingUpperVal: 600,
    ratingVal: 1,
    icon: <ErrorIcon color="primary" />,
    iconResp: (
      <ErrorIcon
        color="primary"
        sx={{
          fontSize: { xs: "1.25rem", sm: "1.5rem", xl: "2.1875rem" },
        }}
      />
    ),
    backgroundColor: customTheme.palette.primary.background,
    descriptionShort: "Very Bad Score",
    descriptionFull:
      "This score indicates that you have an extremely high risk of defaulting on a loan or credit card payment. You may have a history of missed or late payments, defaulted accounts, or judgements. You'll most likely not get approved for a loan.",
  },
  {
    title: "Improvement Starts with Action",
    ratingLowerVal: 601,
    ratingUpperVal: 750,
    ratingVal: 2,
    icon: <ErrorIcon color="primary" />,
    iconResp: (
      <ErrorIcon
        color="primary"
        sx={{
          fontSize: { xs: "1.25rem", sm: "1.5rem", xl: "2.1875rem" },
        }}
      />
    ),
    backgroundColor: customTheme.palette.primary.background,
    descriptionShort: "Bad Score",
    descriptionFull:
      "This score suggests that you have a higher than average risk of defaulting on a loan or credit card payment. You may have some negative information on your credit report, such as late payments, high credit utilisation, or accounts handed over for collections.",
  },
  {
    title: "Strengthen Your Financial Path",
    ratingLowerVal: 751,
    ratingUpperVal: 850,
    ratingVal: 2,
    icon: <ErrorIcon color="brandedOrange" />,
    iconResp: (
      <ErrorIcon
        color="brandedOrange"
        sx={{
          fontSize: { xs: "1.25rem", sm: "1.5rem", xl: "2.1875rem" },
        }}
      />
    ),
    backgroundColor: customTheme.palette.brandedOrange.background,
    descriptionShort: "Fair Score",
    descriptionFull:
      "This score indicates that you have a moderate risk of defaulting on a loan or credit card payment. You may have some negative information on your credit report, but it is likely outweighed by positive credit behaviours, such as paying your bills on time and keeping your credit balances low.",
  },
  {
    title: "Keep Moving Forward",
    ratingLowerVal: 851,
    ratingUpperVal: 875,
    ratingVal: 3,
    icon: <CheckCircleIcon color="textGood" />,
    iconResp: (
      <CheckCircleIcon
        color="textGood"
        sx={{
          fontSize: { xs: "1.25rem", sm: "1.5rem", xl: "2.1875rem" },
        }}
      />
    ),
    backgroundColor: customTheme.palette.textGood.background,
    descriptionShort: "Okay Score",
    descriptionFull:
      "This score suggests that you have a relatively low risk of defaulting on a loan or credit card payment. You likely have a good credit history with a mix of positive and negative information.",
  },
  {
    title: "You're on the Right Path",
    ratingLowerVal: 876,
    ratingUpperVal: 900,
    ratingVal: 4,
    icon: <CheckCircleIcon color="textGood" />,
    iconResp: (
      <CheckCircleIcon
        color="textGood"
        sx={{
          fontSize: { xs: "1.25rem", sm: "1.5rem", xl: "2.1875rem" },
        }}
      />
    ),
    backgroundColor: customTheme.palette.textGood.background,
    descriptionShort: "Good Score",
    descriptionFull:
      "This score indicates that you have a low to medium-low risk of defaulting on a loan or credit card payment. You likely have a positive credit history with a record of on-time payments, low credit utilisation, and a mix of credit accounts.",
  },
  {
    title: "Your Hard Work Shines!",
    ratingLowerVal: 901,
    ratingUpperVal: 1000,
    ratingVal: 5,
    icon: <CheckCircleIcon color="brandedBlue" />,
    iconResp: (
      <CheckCircleIcon
        color="brandedBlue"
        sx={{
          fontSize: { xs: "1.25rem", sm: "1.5rem", xl: "2.1875rem" },
        }}
      />
    ),
    backgroundColor: customTheme.palette.brandedBlue.background,
    descriptionShort: "Excellent Score",
    descriptionFull:
      "This score suggests that you have a very low risk of defaulting on a loan or credit card payment. You likely have an excellent credit history with a long record of on-time payments, low credit utilisation, and a diverse mix of credit accounts.",
  },
];
