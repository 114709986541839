import AccordionComp from "../../../components/base_components/AccordionComp";
import customTheme from "../../../constants/Theme";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import {
  currencyFormatter,
  formatDate,
  normaliseString,
} from "../../../utils/generalHelper";
import PaymentHistoryGrid from "./PaymentHistoryGrid";

const AccountComp = ({ account, i, groupLength, accordionSx }) => {
  /**
   * Calculates credit utilization for the given account.
   *
   * @param account
   * @returns {number}
   */
  const calculateCreditUtilization = (account) => {
    // If the current balance is not a positive number, then return 0
    if (!(account.current_balance > 0)) return 0;

    // If the credit limit is not a positive number, then return 100
    if (!(account.opening_balance_credit_limit > 0)) return 100;

    // Both the balance & limit are positive numbers, calculate usage percentage
    return (
      Math.min(
        account.current_balance / account.opening_balance_credit_limit,
        1,
      ) * 100
    );
  };

  /**
   * Determines what the account status label should be on the accordion header.
   *
   * @returns {*|string}
   */
  const headerAccountStatus = () => {
    // Determine label color palette.
    let labelColorPalette = customTheme.palette.textMuted;

    const textGoodLabels = [
      "Up to Date",
      "Paid Up",
      "Settlement of Adverse Arrears",
    ];
    if (textGoodLabels.includes(account.account_status_label)) {
      labelColorPalette = customTheme.palette.textGood;
    }

    const textBadLabels = [
      "In Arrears",
      "Lapsed Policy",
      "Handed Over",
      "Prescription Interrupted",
      "Repossession",
      "Facility Revoked",
    ];
    if (textBadLabels.includes(account.account_status_label)) {
      labelColorPalette = customTheme.palette.textBad;
    }

    return (
      <Box
        sx={{
          padding: { xs: "3px 7px", sm: "5px 10px" },
          borderRadius: "0.5rem",
          backgroundColor: labelColorPalette.background,
          color: labelColorPalette.main,
        }}
      >
        <Typography
          component="span"
          sx={{
            whiteSpace: "nowrap",
            fontSize: {
              xs: "0.6rem !important",
              sm: "0.875rem !important",
            },
            fontWeight: "400 !important",
          }}
        >
          {account.account_status_label}
        </Typography>
      </Box>
    );
  };

  return (
    <AccordionComp
      key={i}
      sx={accordionSx(i, groupLength)}
      headerOverride={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: {
                xs: "0.875rem !important",
                sm: "1rem !important",
              },
              fontWeight: {
                xs: "400 !important",
                sm: "600 !important",
              },
              paddingRight: "0.5rem",
            }}
          >
            {normaliseString(account.name)}
          </Typography>
          <Box
            sx={{
              marginRight: {
                xs: "0.5rem",
                lg: "1rem",
              },
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                lg: "row",
              },
              alignItems: {
                xs: "flex-end",
                lg: "center",
              },
              justifyContent: {
                xs: "center",
                lg: "space-between",
              },
              minWidth: {
                xs: "100px",
                sm: "140px",
                lg: "unset",
              },
            }}
          >
            {headerAccountStatus()}

            <Typography
              component="span"
              textAlign="right"
              sx={{
                whiteSpace: "nowrap",
                fontSize: {
                  xs: "0.8rem !important",
                  sm: "1rem !important",
                },
                fontWeight: {
                  xs: "400 !important",
                  sm: "600 !important",
                },
                minWidth: {
                  xs: "unset",
                  lg: "140px",
                },
                marginBottom: {
                  xs: "0.5rem",
                  lg: 0,
                },
              }}
            >
              {currencyFormatter.format(account.installment_amount || 0)} p/m
            </Typography>
          </Box>
        </Box>
      }
      contentOverride={
        <Grid
          container
          padding={{
            xs: "0.5rem",
            sm: "1rem",
          }}
        >
          {/* Account details */}
          <Grid item xs={12} lg={7} container>
            {/* Date opened */}
            <Grid item xs={6} marginY={1}>
              <Typography component="h6" variant="body1Resp">
                Opened
              </Typography>
              <Typography component="p" variant="body1BoldResp">
                {formatDate(account.date_account_opened) || "No date set"}
              </Typography>
            </Grid>

            {/* Account number */}
            <Grid item xs={6} marginY={1}>
              <Typography component="h6" variant="body1Resp">
                Account Number
              </Typography>
              <Typography component="p" variant="body1BoldResp">
                {account.account_no}
              </Typography>
            </Grid>

            {/* Date last paid */}
            <Grid item xs={6} marginY={1}>
              <Typography component="h6" variant="body1Resp">
                Last Paid
              </Typography>
              <Typography component="p" variant="body1BoldResp">
                {formatDate(account.last_payment) || "No date set"}
              </Typography>
            </Grid>

            {/* Account status */}
            <Grid item xs={6} marginY={1}>
              <Typography component="h6" variant="body1Resp">
                Account Status
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component="p" variant="body1BoldResp">
                  {account.account_status}
                </Typography>
              </Box>
            </Grid>

            {/* Amount in arrears */}
            <Grid item xs={6} marginY={1}>
              <Typography component="h6" variant="body1Resp">
                Arrears
              </Typography>
              <Typography
                component="p"
                variant="body1BoldResp"
                sx={{
                  color:
                    account.amount_overdue > 0
                      ? customTheme.palette.textBad.main
                      : customTheme.palette.text.primary,
                }}
              >
                {currencyFormatter.format(account.amount_overdue || 0)}
              </Typography>
            </Grid>

            {/* Current installment */}
            <Grid item xs={6} marginY={1}>
              <Typography component="h6" variant="body1Resp">
                Current Installment
              </Typography>
              <Typography component="p" variant="body1BoldResp">
                {currencyFormatter.format(account.installment_amount || 0)}
              </Typography>
            </Grid>
          </Grid>

          {/* Payments details */}
          <Grid item xs={12} lg={5} container>
            {/* Credit balance & limit */}
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              marginY={1}
            >
              <Grid item xs={6}>
                <Typography component="h6" variant="body1Resp">
                  Current Balance
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  component="h6"
                  variant="body1Resp"
                  textAlign="right"
                >
                  Credit Limit
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <LinearProgress
                  variant="determinate"
                  color="brandedBlue"
                  value={calculateCreditUtilization(account)}
                  sx={{
                    height: "0.5625rem",
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography component="p" variant="body1BoldResp">
                  {currencyFormatter.format(account.current_balance || 0)}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  component="p"
                  variant="body1BoldResp"
                  textAlign="right"
                >
                  {currencyFormatter.format(
                    account.opening_balance_credit_limit || 0,
                  )}
                </Typography>
              </Grid>
            </Grid>

            {/* History for 6months */}
            <Grid item xs={12} container marginY={1}>
              <Grid item xs={12} container alignItems="end">
                <Typography component="h6" variant="body1Resp">
                  Payment History (6 months)
                </Typography>
              </Grid>

              <PaymentHistoryGrid account={account} compact={true} />
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export default AccountComp;
