import { useEffect, useState, useRef } from "react";
import Chart from "chart.js/auto";
import {
  getDoughnutDefaults,
  getDoughnutWallThickness,
} from "../../utils/chartjsHelper";

const { cutout, startAngle, circumference, spacing } = getDoughnutDefaults();

const DoughnutChart = ({
  data,
  labels,
  backgroundColor,
  datasets,
  plugins,
  legend,
  optionsOverride,
  ignoreRefresh,
}) => {
  const chartRef = useRef(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (ignoreRefresh) return;

    const resizeHandler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data && !datasets) return;

    let myChart = null;

    const maxDataSegments =
      data?.length || Math.max(...datasets.map((ds) => ds.data.length));
    const chartData = {
      labels: labels,
      datasets:
        datasets?.length > 0
          ? datasets
          : [
              {
                data: data,
                backgroundColor: backgroundColor,
              },
            ],
    };

    const config = {
      type: "doughnut",
      data: chartData,
      options: {
        animation: {
          duration: 1000,
        },
        cutout: `${cutout}%`,
        aspectRatio: 1,
        responsive: true,
        borderRadius: getDoughnutWallThickness(chartRef.current.width, cutout),
        borderWidth: 0,
        spacing: spacing * (maxDataSegments - 1 / maxDataSegments),
        rotation: 0 - (startAngle - 90),
        circumference: circumference,
        layout: {
          autoPadding: false, // Not working as expected
        },
        plugins: {
          legend: legend || {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        ...optionsOverride,
      },
      plugins: plugins,
    };

    const renderChart = () => {
      if (!chartRef.current) return;
      myChart = new Chart(chartRef.current, config);
    };

    const destroyChart = () => {
      if (myChart) {
        myChart.destroy();
      }
    };

    renderChart();
    return () => destroyChart();
  }, [
    windowSize.width,
    optionsOverride,
    data,
    datasets,
    labels,
    backgroundColor,
    legend,
    plugins,
  ]);

  return (
    <div
      className="chart-container"
      style={{ position: "relative", width: "100%", height: "100%" }}
    >
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default DoughnutChart;
