import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../utils/errorHelper";
import customTheme from "../../../constants/Theme";
import SmartSolutionsController from "../../../controllers/SmartSolutionsController";
import BasePage from "../../BasePage";
import ButtonLinkComp from "../../../components/base_components/ButtonLinkComp";
import SimplePopup from "../../../components/templates/SimplePopup";
import DebtReviewTab from "./tabs/DebtReviewTab";
import CreditCoverTab from "./tabs/CreditCoverTab";
import ShortTermInsuranceTab from "./tabs/ShortTermInsuranceTab";

const SmartSolutionsPage = ({ ...props }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [solutionsDetails, setSolutionsDetails] = useState(null);
  const [isDebtReviewSelected, setIsDebtReviewSelected] = useState(false);
  const [isCreditCoverSelected, setIsCreditCoverSelected] = useState(false);
  const [isInsuranceSelected, setIsInsuranceSelected] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    let mounted = true;

    Promise.allSettled([getSolutionsDetails()])
      .then((responses) => {
        if (mounted) {
          setSolutionsDetails(responses[0].value);
        }
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!solutionsDetails) return;

    if (hasDebtReviewDetails()) {
      selectDebtReview();
    } else if (hasCreditCoverDetails()) {
      selectCreditCover();
    } else if (hasAnyInsurance()) {
      selectInsurance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutionsDetails]);

  const getSolutionsDetails = () => {
    return new Promise(async (res, rej) => {
      try {
        const response = await SmartSolutionsController.getSolutions();

        if (!response || Object.keys(response).length <= 0) {
          enqueueSnackbar(
            "No SmartSolutions were received from the server. Please try again later",
            { variant: "error" },
          );
          rej();
          return;
        }

        res(response);
      } catch (error) {
        const errMsg = getErrorMessage(error);

        // Check if request was aborted
        if (errMsg === null) return;

        enqueueSnackbar(errMsg, { variant: "error" });
        rej(errMsg);
      }
    });
  };

  const submitQuoteRequest = async (product) => {
    try {
      await SmartSolutionsController.submitCallToAction(product);

      setShowPopup(true);
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
      return false;
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const hasDebtReviewDetails = () => {
    return solutionsDetails?.debt_review?.qualifies_for_debt_review === true;
  };

  const hasCreditCoverDetails = () => {
    return solutionsDetails?.credit_cover?.qualifies_for_credit_cover === true;
  };

  const hasAnyInsurance = () => {
    return (
      solutionsDetails?.short_term_insurance?.show_vehicle_insurance === true ||
      solutionsDetails?.short_term_insurance?.show_building_insurance ===
        true ||
      solutionsDetails?.short_term_insurance?.show_home_content_insurance ===
        true ||
      solutionsDetails?.short_term_insurance?.show_general_insurance === true
    );
  };

  const hasAnySolutions = () => {
    return (
      hasDebtReviewDetails() || hasCreditCoverDetails() || hasAnyInsurance()
    );
  };

  const selectDebtReview = () => {
    setIsDebtReviewSelected(true);
    setIsCreditCoverSelected(false);
    setIsInsuranceSelected(false);
  };

  const selectCreditCover = () => {
    setIsDebtReviewSelected(false);
    setIsCreditCoverSelected(true);
    setIsInsuranceSelected(false);
  };

  const selectInsurance = () => {
    setIsDebtReviewSelected(false);
    setIsCreditCoverSelected(false);
    setIsInsuranceSelected(true);
  };

  return (
    <>
      <BasePage ignoreAuthentication>
        <Box width="100%" maxWidth="600px">
          {/* Back button */}
          <Box
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              display: "flex",
              width: "fit-content",
              marginBottom: "2rem",
            }}
          >
            <ExpandCircleDownIcon
              sx={{
                transform: "rotate(90deg)",
                marginRight: "10px",
              }}
            />
            <Typography variant="body1" component="span">
              back
            </Typography>
          </Box>

          {/* Page title, descriptive text & tab buttons */}
          <Typography component="h1" variant="pageTitle1Resp" marginY={4}>
            What solution do you need?
          </Typography>

          {/* Page descriptive text */}
          <Typography component="p" variant="body1Resp" marginBottom={2}>
            Embark on your journey to financial health with ThreeSixty.me’s
            recommended solutions.
          </Typography>

          {/* Page tab buttons */}
          {!isLoading && hasAnySolutions() && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <ButtonLinkComp
                onClick={selectDebtReview}
                endIcon={null}
                sx={{
                  display: !hasDebtReviewDetails()
                    ? "none !important"
                    : "unset",
                  width: { xs: "100%", sm: "auto" },
                  margin: "5px",
                  backgroundColor: isDebtReviewSelected
                    ? `${customTheme.palette.brandedTeal.main} !important`
                    : "unset",
                }}
              >
                Get Help With Your Debt
              </ButtonLinkComp>
              <ButtonLinkComp
                onClick={selectCreditCover}
                endIcon={null}
                sx={{
                  display: !hasCreditCoverDetails()
                    ? "none !important"
                    : "unset",
                  width: { xs: "100%", sm: "auto" },
                  margin: "5px",
                  backgroundColor: isCreditCoverSelected
                    ? `${customTheme.palette.brandedTeal.main} !important`
                    : "unset",
                }}
              >
                <span>Cover Your Debt</span>
              </ButtonLinkComp>
              <ButtonLinkComp
                onClick={selectInsurance}
                endIcon={null}
                sx={{
                  display: !hasAnyInsurance() ? "none !important" : "unset",
                  width: { xs: "100%", sm: "auto" },
                  margin: "5px",
                  backgroundColor: isInsuranceSelected
                    ? `${customTheme.palette.brandedTeal.main} !important`
                    : "unset",
                }}
              >
                Get Insurance
              </ButtonLinkComp>
            </Box>
          )}
        </Box>

        <Divider sx={{ margin: "1rem 0" }} />

        {/* Loading indicator */}
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <Typography component="h1" variant="pageTitle1Resp" gutterBottom>
              Loading Your Solutions Details
            </Typography>
            <CircularProgress size="5rem" />
          </Box>
        )}

        {/* Page details for selected tab */}
        {!isLoading && !hasAnySolutions() ? (
          <Typography
            component="p"
            variant="body1BoldResp"
            color={customTheme.palette.warning.main}
            textAlign="center"
            marginY={4}
          >
            No solution details received from the server. Please contact support
            if this issue persists.
          </Typography>
        ) : (
          <Box mt={2} pb={{ xs: 5, sm: 2 }}>
            {isDebtReviewSelected && (
              <DebtReviewTab
                callToAction={submitQuoteRequest}
                details={solutionsDetails?.debt_review}
              />
            )}
            {isCreditCoverSelected && (
              <CreditCoverTab
                callToAction={submitQuoteRequest}
                details={solutionsDetails?.credit_cover}
              />
            )}
            {isInsuranceSelected && (
              <ShortTermInsuranceTab
                callToAction={submitQuoteRequest}
                details={solutionsDetails?.short_term_insurance}
              />
            )}
          </Box>
        )}
      </BasePage>

      {/* Call to action popup */}
      <SimplePopup
        showPopup={showPopup}
        handleClose={handlePopupClose}
        titleComp={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "4.6875rem",
              background: customTheme.palette.brandedTeal.background,
            }}
          >
            <VerifiedIcon
              fontSize="large"
              sx={{
                color: customTheme.palette.brandedTeal.main,
                margin: "0.75363rem",
              }}
            />
            <Typography
              component="h5"
              variant="popupTitle"
              sx={{
                color: customTheme.palette.brandedTeal.main,
              }}
            >
              Inquiry Submitted
            </Typography>
          </Box>
        }
        contentComp={
          <Typography component="h6" variant="popupContent">
            Your inquiry has been submittted and an advisor will be in contact
            shortly.
          </Typography>
        }
        actionText="Okay"
      />
    </>
  );
};

export default SmartSolutionsPage;
