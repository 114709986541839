import { Button, CircularProgress } from "@mui/material";

const ButtonComp = ({ isLoading, ...props }) => {
  return (
    <Button
      fullWidth
      variant="contained"
      disabled={props.disabled || isLoading}
      {...props}
      sx={{
        maxWidth: "284px",
        padding: "0.75rem 1rem",
        textTransform: "capitalize",
        ...props.sx,
      }}
    >
      {isLoading ? <CircularProgress /> : props.children}
    </Button>
  );
};

export default ButtonComp;
