import BaseController from "./BaseController";

class Controller extends BaseController {
  constructor() {
    super();
    this.LoginURL = "/login";
    this.ResetPasswordURL = "/reset-password";
    this.ResetPasswordOtpURL = "/reset-password/otp";
    this.ResetPasswordValidateURL = "/reset-password/validate";
  }

  login = (body) => {
    return this.sendRequest("POST", this.LoginURL, null, body);
  };

  requestResetPasswordToken = (body) => {
    return this.sendRequest("POST", this.ResetPasswordURL, null, body);
  };

  resetPassword = (body) => {
    return this.sendRequest("POST", this.ResetPasswordOtpURL, null, body);
  };

  validateResetPassword = (body) => {
    return this.sendRequest("POST", this.ResetPasswordValidateURL, null, body);
  };
}

const AuthController = new Controller();

export default AuthController;
