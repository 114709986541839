import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { normaliseString } from "../../utils/generalHelper";

const AccordionComp = ({
  header,
  headerOverride,
  contentItems,
  contentOverride,
  ...props
}) => {
  return (
    <Accordion {...props}>
      <AccordionSummary expandIcon={<ExpandCircleDownIcon />}>
        {headerOverride}
        {!headerOverride && (
          <Typography component="h6" variant="body1Bold">
            {header}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {contentOverride}
        {!contentOverride &&
          contentItems.map((item, i) => (
            <React.Fragment key={i}>
              {i > 0 && <Divider />}
              <Grid container padding="1rem">
                {Object.keys(item).map((key, k) => (
                  <Grid
                    item
                    key={`${i}${k}`}
                    //xs={12}
                    xs={item[key].fullWidth ? 12 : 6}
                    marginY={1}
                  >
                    <Typography component="h6" variant="body1">
                      {normaliseString(key)}
                    </Typography>
                    <Typography component="p" variant="body1Bold">
                      {item[key].value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionComp;
