import * as Yup from "yup";

export const defaultRequiredMsg = "This is a required field";
export const passwordRules = [
  "Must be at least 8 characters",
  "Must contain at least one lowercase letter",
  "Must contain at least one uppercase letter",
  "Must contain at least one numeral",
  "Must contain at least one special symbol",
];

export const getSchemaForRequiredText = (requiredMsg) => {
  return Yup.string().required(requiredMsg ?? defaultRequiredMsg);
};

export const getSchemaEmail = (isRequired, matchMsg, requiredMsg) => {
  //let schema = Yup.string().email(matchMsg ?? "Email is invalid");
  let schema = Yup.string().matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    matchMsg ?? "Invalid email address",
  );

  if (isRequired) {
    schema = schema.required(requiredMsg ?? defaultRequiredMsg);
  }

  return schema;
};

export const getSchemaForTelNumber = (isRequired, matchMsg, requiredMsg) => {
  let schema = Yup.string().matches(
    /^(?:\+27|0)[1-8][0-9]{8}$/,
    matchMsg ?? "Invalid mobile number",
  );

  if (isRequired) {
    schema = schema.required(requiredMsg ?? defaultRequiredMsg);
  }

  return schema;
};

export const getSchemaForConfirmTelNumber = (telNumberFieldName) => {
  return getSchemaForTelNumber(true).oneOf(
    [Yup.ref(telNumberFieldName)],
    "Mobile number must match",
  );
};

export const getSchemaForAddress = (isRequired, requiredMsg) => {
  let schema = Yup.object().shape({
    line_1: Yup.string().required("Address line 1 is required"),
    line_2: Yup.string(),
    suburb: Yup.string().required("Suburb is required"),
    city: Yup.string().required("Province is required"),
    postal_code: Yup.string().required("Postal code is required"),
  });

  if (isRequired) {
    schema = schema.required(requiredMsg ?? defaultRequiredMsg);
  }

  return schema;
};

export const getSchemaForID = (
  isRequired,
  checkAge,
  matchMsg,
  requiredMsg,
  ageMsg,
) => {
  let schema = Yup.string()
    .trim()
    .matches(
      /^(?:\d{2})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])(?:\d{6})(?:\d)$/,
      matchMsg ?? "Invalid ID number",
    );

  if (isRequired) {
    schema = schema.required(requiredMsg ?? defaultRequiredMsg);
  }

  // Add custom validation for Luhn algorithm
  schema = schema.test("luhn-validation", "Invalid ID number", (value) =>
    validateSAIDNumber(value),
  );

  // Add custom validation for age
  if (checkAge) {
    schema = schema.test(
      "age-validation",
      ageMsg ?? "You must be at least 18 years old",
      (value) => {
        if (!value) return false;

        // Extract the birth date from the ID number
        const year = parseInt(value.substring(0, 2), 10);
        const month = parseInt(value.substring(2, 4), 10) - 1;
        const day = parseInt(value.substring(4, 6), 10);

        // Determine the century
        const currentYear = new Date().getFullYear();
        const centuryPrefix = year + 2000 <= currentYear ? 2000 : 1900;
        const birthDate = new Date(centuryPrefix + year, month, day);

        // Calculate age
        const age = calculateAgeFromId(birthDate);

        // Check if the person is at least 18 years old
        return age >= 18;
      },
    );
  }

  return schema;
};

const validateSAIDNumber = (idNumber) => {
  /* 
        Use the Lunh algorithm to perform the checksum 
        The code below is copied from an NPM package "south-african-id-parser"
        created by Justin Wernick. Credit:
        https://www.npmjs.com/package/south-african-id-parser
    */
  idNumber = idNumber.replace(/\s/g, "").replace(/-/g, "");

  if (!/^\d{13}$/.test(idNumber)) {
    return false;
  }

  var checkDigit = parseInt(idNumber[12], 10);

  var oddDigitsSum = 0;

  for (var i = 0; i < idNumber.length - 1; i += 2) {
    oddDigitsSum += parseInt(idNumber[i], 10);
  }
  var evenDigits = "";
  for (var j = 1; j < idNumber.length - 1; j += 2) {
    evenDigits += idNumber[j];
  }
  evenDigits = parseInt(evenDigits, 10);
  evenDigits *= 2;
  evenDigits = "" + evenDigits;

  var sumOfEvenDigits = 0;
  for (var k = 0; k < evenDigits.length; k++) {
    sumOfEvenDigits += parseInt(evenDigits[k], 10);
  }
  var total = sumOfEvenDigits + oddDigitsSum;
  var computedCheckDigit = 10 - (total % 10);

  if (computedCheckDigit === 10) {
    computedCheckDigit = 0;
  }
  return computedCheckDigit === checkDigit;
};

const calculateAgeFromId = (birthDate) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
};

export const getSchemaForPassword = (isRequired, requiredMsg) => {
  let schema = Yup.string()
    .min(8, passwordRules[0])
    .matches(/[a-z]/, passwordRules[1])
    .matches(/[A-Z]/, passwordRules[2])
    .matches(/[0-9]/, passwordRules[3])
    .matches(/[!@#$%^&*(),.?":{}|<>]/, passwordRules[4]);

  if (isRequired) {
    schema = schema.required(requiredMsg ?? defaultRequiredMsg);
  }

  return schema;
};

export const getSchemaForPasswordConfirmation = (passwordFieldName) => {
  return getSchemaForPassword(true).oneOf(
    [Yup.ref(passwordFieldName)],
    "Passwords must match",
  );
};

export const getSchemaForOTP = (length) => {
  return Yup.string().length(length).required();
};

export const validateOtpChars = (value, index) => {
  const isNumber = typeof value === "number";
  const isString = typeof value === "string";

  return (isNumber || (isString && value !== "")) && !isNaN(Number(value));
};
