import BaseController from "./BaseController";

class Controller extends BaseController {
  constructor() {
    super();
    this.supportURL = "/support";
  }

  postContactUsForm = (body) => {
    return this.sendRequest("POST", this.supportURL, null, body);
  };
}

const ContactUsController = new Controller();

export default ContactUsController;
