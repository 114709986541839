import { useState } from "react";
import { useNavigate } from "react-router";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as Schemas from "../../../utils/validationHelper";
import { getErrorMessage } from "../../../utils/errorHelper";
import CustomTheme from "../../../constants/Theme";
import UserProfileController from "../../../controllers/UserProfileController";
import BasePage from "../../BasePage";
import InputComp from "../../../components/base_components/InputComp";
import ButtonComp from "../../../components/base_components/ButtonComp";
import ErrorMsgComp from "../../../components/base_components/ErrorMsgComp";
import LinkComp from "../../../components/base_components/LinkComp";
import SimplePopup from "../../../components/templates/SimplePopup";
import PageBackgroundContainer from "../../../components/templates/PageBackgroundContainer";
import BackgroundImg from "../../../assets/images/background_white.png";

const initFormValues = {
  old_password: "",
  new_password: "",
  passwordConfirmation: "",
};

const ChangePasswordRequest = ({ ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showResultPopup, setShowResultPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [hasErred, setHasErred] = useState(false);

  const validations = Yup.object({
    old_password: Schemas.getSchemaForRequiredText(),
    new_password: Schemas.getSchemaForPassword(true),
    passwordConfirmation:
      Schemas.getSchemaForPasswordConfirmation("new_password"),
  });

  const handleSubmit = async (values, actions) => {
    try {
      setHasErred(false);
      setErrorMsg("");

      const response = await UserProfileController.changePassword(values);

      actions.resetForm();

      if (response?.message?.toLowerCase() !== "success") {
        setHasErred(true);
        setErrorMsg(
          response?.message?.length > 0
            ? response.message
            : "An unexpected issue occurred while resetting password. Please try again later",
        );
      }

      setShowResultPopup(true);
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      if (
        errMsg.toLowerCase().includes("new_password") &&
        errMsg.toLowerCase().includes("same as old password")
      ) {
        setErrorMsg(
          "Your new password cannot be the same as your current/old password",
        );
        return;
      } else if (
        errMsg.toLowerCase().includes("old_password") &&
        errMsg.toLowerCase().includes("invalid")
      ) {
        setErrorMsg("Your current password is not correct");
        return;
      }

      enqueueSnackbar(errMsg, { variant: "error" });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleResultPopupClose = () => {
    setShowResultPopup(false);

    if (hasErred) return;

    navigate("/profile");
  };

  return (
    <>
      <BasePage>
        <PageBackgroundContainer
          handleBack={handleBack}
          backgroundImg={BackgroundImg}
          partial
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: { xs: "5rem", sm: 0 },
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "800px",
              }}
            >
              {/* Page title */}
              <Typography component="h1" variant="pageTitle2" marginBottom={2}>
                Change Password
              </Typography>

              {/* Page description */}
              <Typography component="h1" variant="body1Resp" marginBottom={3}>
                Enter your current password associated with your account
              </Typography>

              {/* Form contents */}
              <Formik
                initialValues={initFormValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
                validateOnBlur={false}
              >
                {({
                  values,
                  isSubmitting,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                }) => (
                  <Form noValidate>
                    <Grid container spacing={2}>
                      {/* Current/old password */}
                      <Grid item xs={12}>
                        <InputComp
                          labelText="Current password"
                          type="password"
                          id="old_password"
                          required
                          autoComplete="current-password"
                          value={values.old_password}
                          disabled={isSubmitting}
                          touched={touched}
                          errors={errors}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Forgot password link */}
                      <Grid item xs={12} container justifyContent="flex-end">
                        <LinkComp
                          href="/forgot-password"
                          disabled={isSubmitting}
                        >
                          Don't know your password?
                        </LinkComp>
                      </Grid>

                      {/* New password */}
                      <Grid item xs={12}>
                        <InputComp
                          labelText="Create your new password"
                          type="password"
                          id="new_password"
                          required
                          showPasswordHint
                          autoComplete="current-password"
                          value={values.new_password}
                          disabled={isSubmitting}
                          touched={touched}
                          errors={errors}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Password confirmation input */}
                      <Grid item xs={12}>
                        <InputComp
                          labelText="Confirm new password"
                          type="password"
                          id="passwordConfirmation"
                          required
                          autoComplete="current-password"
                          value={values.passwordConfirmation}
                          disabled={isSubmitting}
                          touched={touched}
                          errors={errors}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      {/* Information bubble */}
                      <Grid item xs={12} marginY={2}>
                        <Paper className="infoBubble">
                          <InfoIcon color="brandedTeal" />
                          <Typography
                            component="span"
                            variant="body1Resp"
                            ml={1}
                          >
                            Your new password must be different from previous
                            used passwords.
                          </Typography>
                        </Paper>
                      </Grid>

                      {/* Submit button */}
                      <Grid
                        item
                        xs={12}
                        mb={2}
                        container
                        justifyContent={{
                          xs: "center",
                          sm: "flex-start",
                        }}
                      >
                        <ButtonComp type="submit" isLoading={isSubmitting}>
                          Change Password
                        </ButtonComp>
                      </Grid>

                      {/* Error message */}
                      {errorMsg && (
                        <Grid item xs={12} container justifyContent="center">
                          <ErrorMsgComp errorMessage={errorMsg} />
                        </Grid>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </PageBackgroundContainer>
      </BasePage>

      <SimplePopup
        showPopup={showResultPopup}
        handleClose={handleResultPopupClose}
        titleComp={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "4.6875rem",
              background: hasErred
                ? CustomTheme.palette.textBad.background
                : CustomTheme.palette.textVeryGood.background,
            }}
          >
            {hasErred ? (
              <ErrorIcon
                fontSize="large"
                sx={{
                  color: CustomTheme.palette.textBad.main,
                  margin: "0.75363rem",
                }}
              />
            ) : (
              <VerifiedUserIcon
                fontSize="large"
                sx={{
                  color: CustomTheme.palette.textVeryGood.main,
                  margin: "0.75363rem",
                }}
              />
            )}
            <Typography
              component="h5"
              variant="popupTitle"
              sx={{
                color: hasErred
                  ? CustomTheme.palette.textBad.main
                  : CustomTheme.palette.textVeryGood.main,
              }}
            >
              {hasErred ? "Uh oh..." : "Success"}
            </Typography>
          </Box>
        }
        contentComp={
          <Typography component="h6" variant="popupContent">
            {hasErred
              ? "Sorry, we could not change your password"
              : "Your password has been successfully reset."}
          </Typography>
        }
        actionText={hasErred ? "Retry" : "Continue to My Profile"}
      />
    </>
  );
};

export default ChangePasswordRequest;
