import { Box, Container, Rating, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { creditScoreRatingData } from "../../../constants/appData";
import BadgeComp from "../../../components/base_components/BadgeComp";

const ScoreDescription = ({ creditProfile }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      {creditScoreRatingData.map((item, i) => (
        <Container key={i}>
          {item.ratingLowerVal <= creditProfile.credit_score &&
            creditProfile.credit_score <= item.ratingUpperVal && (
              <>
                {/* Description title */}
                <Typography
                  component="h2"
                  variant="pageTitle2Small"
                  gutterBottom
                >
                  {item.title}
                </Typography>

                <Box
                  my={2}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  {/* Rating score */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        md: "row",
                      },
                    }}
                  >
                    <Typography component="span" variant="body1Resp" mr={1}>
                      {item.ratingLowerVal} - {item.ratingUpperVal}
                    </Typography>
                    <Rating
                      readOnly
                      value={item.ratingVal}
                      size={isMobile ? "small" : "medium"}
                    />
                  </Box>

                  {/* Rating description */}
                  <BadgeComp
                    icon={item.icon}
                    description={item.descriptionShort}
                    backgroundColor={item.backgroundColor}
                  />
                </Box>

                {/* Score full description */}
                <Typography
                  component="p"
                  variant="body1Resp"
                  textAlign="justify"
                  mt={1}
                >
                  {item.descriptionFull}
                </Typography>
              </>
            )}
        </Container>
      ))}
    </Box>
  );
};

export default ScoreDescription;
