import { useEffect, useState, useRef } from "react";
import { Backdrop, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useNudgeContext } from "../../contexts/NudgeContext";
import ButtonComp from "../base_components/ButtonComp";

const NudgeTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.secondary.main,
    padding: "2rem",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1501,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  },
}));

const ChildWrapper = styled("div")(({ theme }) => ({
  position: "relative",
}));

const NudgeWrapper = ({ id, ...props }) => {
  const { getNudgeDetails, showingNudgeId } = useNudgeContext();
  const [nudgeDetails, setNudgeDetails] = useState(null);
  const [showNudge, setShowNudge] = useState(false);
  const childRef = useRef(null);

  useEffect(() => {
    const details = getNudgeDetails(id);

    if (!details) return;

    setNudgeDetails(details);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowNudge(id === showingNudgeId);
  }, [id, showingNudgeId]);

  useEffect(() => {
    if (showNudge && childRef.current) {
      childRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [showNudge]);

  const handleClickAway = (e) => {
    e.stopPropagation();
    setShowNudge(false);

    if (nudgeDetails.callback) nudgeDetails.callback();
  };

  return (
    <>
      <NudgeTooltip
        arrow
        placement="bottom"
        open={showNudge}
        sx={{ maxWidth: { xs: "275px", sm: "350px" } }}
        title={
          nudgeDetails !== null && (
            <>
              <Typography component="h6" variant="popupContent">
                {nudgeDetails.text}
              </Typography>
              <ButtonComp
                onClick={handleClickAway}
                sx={{
                  marginTop: "1rem",
                  padding: "0.75rem 1rem",
                  border: "1px solid black",
                  borderRadius: "5rem",
                  fontSize: "1rem",
                  fontWeigth: 400,
                  lineHeight: "normal",
                  color: "black",
                  background: "transparent",
                }}
              >
                {nudgeDetails.callbackText}
              </ButtonComp>
            </>
          )
        }
      >
        <ChildWrapper
          ref={childRef}
          sx={{ zIndex: showNudge ? 1502 : 1, ...props.sx }}
        >
          {props.children}
        </ChildWrapper>
      </NudgeTooltip>
      <Backdrop
        open={showNudge}
        onClick={handleClickAway}
        sx={{ zIndex: 1500 }}
      />
    </>
  );
};

export default NudgeWrapper;
