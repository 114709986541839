import { Box, Typography } from "@mui/material";

const BadgeComp = ({
  icon,
  description,
  backgroundColor,
  reduceSize,
  ...props
}) => {
  return (
    <Box
      sx={{
        padding: !reduceSize ? "0.5rem" : { xs: "0.3rem", sm: "0.5rem" },
        borderRadius: !reduceSize ? "1rem" : { xs: "0.6rem", sm: "1rem" },
        display: "flex",
        alignItems: "center",
        backgroundColor: backgroundColor,
      }}
    >
      {icon}
      <Typography
        component="span"
        variant={!reduceSize ? "body1Resp" : "body1SmallResp"}
        ml={!reduceSize ? 1 : 0.5}
        textAlign="center"
        width={`calc(100% - ${!reduceSize ? 8 : 4}px)`}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default BadgeComp;
