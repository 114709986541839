import { Box, Typography } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import customTheme from "../../../constants/Theme";
import {
  currencyFormatter,
  getEmptyFolderCard,
  normaliseString,
} from "../../../utils/generalHelper";
import AccountComp from "./AccountComp";
import ErrorIcon from "@mui/icons-material/Error";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import AccountClosedComp from "./AccountClosedComp";

const AccountsTab = ({ accounts, closedAccounts = [], accordionSx }) => {
  /**
   * Render account group.
   *
   * @param groupName
   * @param groupAccounts
   * @param emptyFolderText
   * @returns {JSX.Element}
   */
  const renderGroup = (groupName, groupAccounts, emptyFolderText) => {
    const groupInstallment = groupAccounts.reduce(function (acc, account) {
      return acc + account.installment_amount;
    }, 0);
    const groupStatusPositive =
      groupAccounts.reduce(
        (acc, account) => acc + account.amount_overdue,
        0,
      ) === 0;
    return (
      <Box sx={{ width: "100%", marginY: 2 }}>
        <Box sx={{ width: "100%" }}>
          {/* Group header */}
          <Box
            sx={{
              width: "100%",
              paddingY: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {groupStatusPositive ? (
                <VerifiedUserIcon
                  fontSize="large"
                  sx={{
                    color: customTheme.palette.textGood.main,
                    marginRight: {
                      xs: "0.5rem",
                      sm: "1rem",
                    },
                  }}
                />
              ) : (
                <ErrorIcon
                  fontSize="large"
                  sx={{
                    color: customTheme.palette.textBad.main,
                    marginRight: {
                      xs: "0.5rem",
                      sm: "1rem",
                    },
                  }}
                />
              )}
              <Typography component="span" variant="body1BoldResp">
                {normaliseString(groupName)}
              </Typography>
            </Box>
            <Typography component="span" variant="body1Resp">
              {currencyFormatter.format(groupInstallment || 0)} p/m
            </Typography>
          </Box>

          {/* Empty group */}
          {groupAccounts.length === 0 && getEmptyFolderCard(emptyFolderText)}

          {/* Accounts */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {groupAccounts.map((account, i) => (
              <AccountComp
                key={i}
                account={account}
                i={i}
                groupLength={groupAccounts.length}
                accordionSx={accordionSx}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  /**
   * Render closed accounts.
   *
   * @returns {JSX.Element}
   */
  const renderClosedAccounts = () => {
    return (
      <Box sx={{ width: "100%", marginY: 2 }}>
        <Box sx={{ width: "100%" }}>
          {/* Group header */}
          <Box
            sx={{
              width: "100%",
              paddingY: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DoNotDisturbOnIcon
                fontSize="large"
                sx={{
                  color: customTheme.palette.chartColorNeutral.main,
                  marginRight: {
                    xs: "0.5rem",
                    sm: "1rem",
                  },
                }}
              />
              <Typography component="span" variant="body1BoldResp">
                {normaliseString("Closed Accounts")}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {closedAccounts.map((account, i) => (
              <AccountClosedComp
                key={i}
                account={account}
                i={i}
                groupLength={closedAccounts.length}
                accordionSx={accordionSx}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  /**
   * Render component.
   */
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {/* Unsecured */}
      {renderGroup(
        "Unsecured",
        accounts.filter((account) => account.grouping === "unsecured"),
        "You have no Unsecured accounts",
      )}

      {/* Vehicles */}
      {renderGroup(
        "Vehicles",
        accounts.filter((account) => account.grouping === "vehicles"),
        "You have no Vehicle accounts",
      )}

      {/* Home-Loans */}
      {renderGroup(
        "Home-Loans",
        accounts.filter((account) => account.grouping === "home-loans"),
        "You have no Home Loan accounts",
      )}

      {/* Service-Providers */}
      {renderGroup(
        "Service-Providers",
        accounts.filter((account) => account.grouping === "service-providers"),
        "You have no Service Provider accounts",
      )}

      {/* Insurance */}
      {renderGroup(
        "Insurance",
        accounts.filter((account) => account.grouping === "insurance"),
        "You have no Insurance accounts",
      )}

      {/* Closed */}
      {closedAccounts.length > 0 && renderClosedAccounts()}
    </Box>
  );
};

export default AccountsTab;
