import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { createBreakpoints } from "@mui/system";

const brandedDarkBlue = "#011528";
const brandedBlue = "#27D6D2";
const brandedTeal = "#128A87";
const brandedRed = "#D53C32";
const brandedWhite = "#D9D9D9";
const brandedDarkWhite = "#828282";
const brandedOrange = "#F5841F";

const chartColorVeryBad = "#D53C32";
const chartColorBad = "#D55932";
const chartColorNeutral = "#128A87";
const chartColorGood = "#27D6D2";
const chartColorVeryGood = "#C6D647";

const textBad = "#FF655B";
const textGood = "#0EAC3A";
const textGoodBackground = "#00EA41";

const breakpoints = createBreakpoints({});
/*/
    Default breakpoints:
        xs, extra-small: 0px
        sm, small: 600px
        md, medium: 900px
        lg, large: 1200px
        xl, extra-large: 1536px
/*/

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      vs: 390,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      vl: 1750,
    },
  },
  palette: {
    //mode: "dark"
    background: {
      default: brandedDarkBlue,
    },
    primary: {
      main: brandedRed,
      background: `${brandedRed}40`,
    },
    secondary: {
      main: brandedWhite,
      background: `${brandedWhite}1a`,
    },
    secondaryOnPaper: {
      main: brandedWhite,
    },
    brandedTeal: {
      main: brandedTeal,
      background: `${brandedTeal}40`,
    },
    brandedBlue: {
      main: brandedBlue,
      background: `${brandedBlue}40`,
    },
    brandedOrange: {
      main: brandedOrange,
      background: `${brandedOrange}36`,
    },
    chartColorVeryBad: {
      main: chartColorVeryBad,
    },
    chartColorBad: {
      main: chartColorBad,
    },
    chartColorNeutral: {
      main: chartColorNeutral,
      light: brandedWhite,
      background: `${chartColorNeutral}1a`,
    },
    chartColorGood: {
      main: chartColorGood,
    },
    chartColorVeryGood: {
      main: chartColorVeryGood,
    },
    chartColorEmpty: {
      main: `${brandedTeal}0d`,
    },
    textBad: {
      main: textBad,
      background: `${chartColorVeryBad}40`,
    },
    textGood: {
      main: textGood,
      background: `${textGoodBackground}1a`,
    },
    textVeryGood: {
      main: chartColorVeryGood,
      background: `${chartColorVeryGood}1a`,
    },
    textMuted: {
      main: brandedWhite,
      background: `${brandedWhite}1a`,
    },
    // error: {
    //     main: '#1976d2',
    //     light: '#42a5f5',
    //     dark: '#1565c0',
    //     contrastText: '#fff',
    // },
    warning: {
      main: brandedOrange,
      // light: '#42a5f5',
      // dark: '#1565c0',
      // contrastText: '#fff',
    },
    // info: {
    //     main: '#1976d2',
    //     light: '#42a5f5',
    //     dark: '#1565c0',
    //     contrastText: '#fff',
    // },
    // success: {
    //     main: '#1976d2',
    //     light: '#42a5f5',
    //     dark: '#1565c0',
    //     contrastText: '#fff',
    // },
    text: {
      primary: brandedWhite,
      // secondary: "black",
      // disabled: "grey",
    },
    action: {
      disabled: brandedDarkWhite,
      disabledBackground: brandedWhite,
    },
  },
  typography: {
    fontFamily: "Montserrat",
    body1: {
      fontWeight: 300,
    },
    body1Resp: {
      fontSize: "1rem",
      fontWeight: 300,
      [breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    },
    body1SmallResp: {
      fontSize: "1rem",
      fontWeight: 300,
      [breakpoints.down("md")]: {
        fontSize: "0.875rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.675rem",
      },
    },
    body1SmallBoldResp: {
      fontSize: "1rem",
      fontWeight: 600,
      [breakpoints.down("md")]: {
        fontSize: "0.875rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.675rem",
      },
    },
    body1Bold: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1BoldResp: {
      fontSize: "1rem",
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    },
    pageTitleVeryLarge: {
      fontSize: "3.75rem",
      fontWeight: 400,
      [breakpoints.down("xl")]: {
        fontSize: "3rem",
      },
      [breakpoints.down("lg")]: {
        fontSize: "2.5rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "2rem",
      },
    },
    pageTitleLarge: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: "normal",
      [breakpoints.down("lg")]: {
        fontSize: "2rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "1.8rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    pageTitle1: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    pageTitle1Resp: {
      fontSize: "2rem",
      fontWeight: 700,
      lineHeight: "normal",
      [breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
    pageTitle2: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    pageTitle2Resp: {
      fontSize: "1.5rem",
      fontWeight: 700,
      [breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
    },
    pageTitle2Small: {
      fontSize: "1.25rem",
      fontWeight: 700,
    },
    pageTitle2SmallResp: {
      fontSize: "1.25rem",
      fontWeight: 700,
      [breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    pageBodyBold: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    pageBodyBoldResp: {
      fontSize: "1rem",
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    },
    pageBodySmall: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    pageBodySmallResp: {
      fontSize: "0.875rem",
      fontWeight: 400,
      [breakpoints.down("sm")]: {
        fontSize: "0.5rem",
      },
    },
    pageBodyBoldSmallResp: {
      fontSize: "0.875rem",
      fontWeight: 700,
      [breakpoints.down("sm")]: {
        fontSize: "0.5rem",
      },
    },
    paperHeading: {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: brandedDarkBlue,
    },
    paperSubHeading: {
      fontSize: "1.25rem",
      fontWeight: 700,
      color: brandedDarkBlue,
      [breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    paperBody: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5",
      color: brandedDarkBlue,
      [breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    },
    popupTitle: {
      fontSize: "1.25rem",
      fontWeight: 700,
      color: brandedDarkBlue,
      [breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    popupContent: {
      fontSize: "1rem",
      fontWeight: 600,
      color: brandedDarkBlue,
      [breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    },
    tooltipTitle: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "normal",
    },
    tooltipBody: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
    cardHeadingNeutral: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "normal",
      color: "white",
    },
    cardHeadingPositive: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "normal",
      color: brandedBlue,
    },
    cardHeadingNegative: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "normal",
      color: brandedRed,
    },
    errorMessage: {
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "normal",
      color: brandedRed,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          "& sup": {
            fontSize: "x-small",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: brandedWhite,
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "6.4375rem",
          backgroundColor: `${brandedWhite}40`,
          "& .Mui-focused": {
            borderRadius: "6.4375rem",
            backgroundColor: `${brandedWhite}80`,
            color: `${brandedDarkBlue}`,
            transition: "background-color 0.3s",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "6.4375rem",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiIconButton-root": {
            color: brandedWhite,
          },
          "& .Mui-error": {
            border: "2px solid red",
            borderRadius: "6.4375rem",
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: `${brandedDarkWhite} !important`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: brandedWhite,
          fontSize: "0.875rem",
          fontWeight: 300,
          marginLeft: "10px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === "secondary" && {
            backgroundColor: "transparent",
            color: brandedWhite,
            border: `1px solid #fff`,
            "&: hover": {
              backgroundColor: `${brandedRed}40`,
              border: `1px solid transparent`,
            },
          }),
          ...(ownerState.color === "secondaryOnPaper" && {
            backgroundColor: "transparent",
            color: brandedDarkBlue,
            border: `1px solid ${brandedDarkBlue}`,
            "&: hover": {
              backgroundColor: `${brandedRed}40`,
              borderColor: `${brandedRed}40`,
            },
          }),
          borderRadius: "5rem",
          "&.Mui-disabled": {
            color: brandedWhite,
            backgroundColor: `${brandedRed}40`,
          },
          "&.ButtonLink": {
            borderRadius: "5.1875rem",
            backgroundColor: `${brandedWhite}1a`,
            textTransform: "capitalize",
            fontSize: "1rem",
            height: "3.5rem",
            display: "flex",
            justifyContent: "space-between",
            "& .MuiButton-endIcon svg": {
              fontSize: "2rem",
            },
            "&: hover": {
              backgroundColor: `${brandedWhite}40`,
            },
            "&.Mui-disabled": {
              backgroundColor: `${brandedTeal}1a`,
              color: `${brandedWhite}40`,
              "& .MuiButton-startIcon svg": {
                color: `${brandedWhite}40`,
              },
            },
          },
          "&.ButtonAccordion": {
            borderRadius: "1rem",
            backgroundColor: `${brandedTeal}0d`,
            textTransform: "capitalize",
            fontSize: "1rem",
            height: "3.5rem",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.5rem",
            "&.Expanded": {
              backgroundColor: `${brandedTeal}40`,
            },
            "& .MuiButton-endIcon svg": {
              fontSize: "2rem",
            },
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: brandedBlue,
          fontSize: "0.875rem",
          textDecoration: "none",
          "&: hover": {
            textDecoration: "underline",
          },
          "&.whiteText": {
            color: brandedWhite,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            fontSize: "0.875rem",
            lineHeight: "normal",
          },
          "& .MuiFormControlLabel-label.Mui-disabled": {
            color: brandedDarkWhite,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: brandedWhite,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "2rem",
          color: brandedDarkBlue,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: "3.125rem",
          background: `${brandedWhite}40`,
        },
        bar: ({ ownerState }) => ({
          borderRadius: "3.125rem",
          background: "linear-gradient(270deg, #C6D647 4.01%, #128A87 88.08%)",
          ...(ownerState.color === "brandedBlue" && {
            background: brandedBlue,
          }),
        }),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          stroke: brandedTeal,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: `${brandedDarkBlue}f0`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: brandedWhite,
          backgroundColor: `${brandedTeal}bf`,
          borderRadius: "0.5rem",
          padding: "0.8rem",
          backdropFilter: "blur(8px)",
          "& ul": {
            paddingLeft: "20px",
          },
          "& ul > li": {
            color: `${brandedWhite} !important`,
          },
        },
        arrow: {
          color: `${brandedTeal}bf`,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: `${brandedWhite}1a`,
          backdropFilter: "blur(8px)",
          [breakpoints.down("sm")]: {
            borderRadius: "2rem 2rem 0 0",
            backgroundColor: `${brandedDarkBlue}80`,
          },
          "& .MuiList-root": {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            [breakpoints.down("sm")]: {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              flexWrap: "nowrap",
              width: "100%",
              padding: "1rem 0 0.5rem 0",
            },
          },
          "& .MuiListItemButton-root": {
            [breakpoints.down("sm")]: {
              display: "flex",
              flexDirection: "column",
              padding: "0 0.5rem",
            },
          },
          "& .Mui-selected": {
            backgroundColor: "unset !important",
            "& .MuiListItemIcon-root": {
              color: "white",
            },
            "& .MuiListItemText-primary": {
              color: "white",
            },
          },
          "& .MuiListItemIcon-root": {
            color: `${brandedWhite}80`,
            fontSize: "1rem",
            minWidth: "2rem",
            [breakpoints.down("sm")]: {
              fontSize: "0.875rem",
              minWidth: "unset",
            },
          },
          "& .MuiListItemText-primary": {
            color: `${brandedWhite}80`,
            [breakpoints.down("sm")]: {
              fontSize: "0.875rem",
              textAlign: "center",
            },
          },
          "& a": {
            textDecoration: "none",
          },
          "& .MuiListItem-divider": {
            borderColor: `${brandedWhite}80`,
            marginBottom: "1rem",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: `${brandedDarkBlue}f0`,
          backdropFilter: "blur(8px)",
          boxShadow: "none",
          "& .MuiIconButton-root": {
            color: `${brandedWhite}80`,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: `${brandedWhite}1a`,
          borderRadius: "2rem",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: brandedWhite,
          fontWeight: 300,
          border: "none",
          minWidth: "80px",
          [breakpoints.down("sm")]: {
            fontSize: "0.8rem",
          },
          "&: hover": {
            borderRadius: "2rem",
          },
          "&.Mui-selected": {
            backgroundColor: `${brandedTeal}a0`,
            fontWeight: 600,
            borderRadius: "2rem",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: `${brandedTeal}1a`,
          borderRadius: "1rem !important",
          "&.Mui-expanded": {
            margin: 0,
            backgroundColor: `${brandedWhite}1a`,
            borderColor: `${brandedWhite}40`,
          },
          "& .MuiAccordionSummary-root.Mui-expanded": {
            margin: "0 16px",
            padding: "0",
            borderBottom: `1px solid ${brandedWhite}40`,
          },
          "& .MuiAccordionSummary-content .MuiTypography-root": {
            fontSize: "1rem",
            fontWeight: 600,
            lineHeight: "normal",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: brandedWhite,
          },
          "& .MuiListItem-root": {
            padding: "0.5rem 0",
          },
          "& .MuiListItemIcon-root": {
            color: `${brandedWhite}80`,
            minWidth: "2rem",
          },
          "& .MuiListItemText-primary": {
            color: `${brandedWhite}80`,
            [breakpoints.down("sm")]: {
              fontSize: "0.8rem",
            },
          },
          "& .MuiTypography-body1Bold": {
            color: "white !important",
          },
          "& .MuiListItem-divider": {
            borderColor: `${brandedWhite}80`,
            marginBottom: "1rem",
          },
          "& .MuiDivider-root": {
            borderColor: `${brandedWhite}80`,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: `${brandedTeal}bf`,
            borderRadius: "0.5rem",
            backdropFilter: "blur(8px)",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: `${brandedWhite}40`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.infoBubble": {
            width: "100%",
            padding: "0.5rem 1rem",
            borderRadius: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: `${brandedBlue}1a`,
          },
          "&.card": {
            width: "100%",
            padding: "1rem",
            borderRadius: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: `${brandedWhite}1a`,
            "& .MuiSvgIcon": {
              fontSize: "small",
            },
            "&: hover": {
              cursor: "pointer",
              backgroundColor: `${brandedWhite}40`,
            },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "collapse",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${brandedWhite}1a`,
        },
        head: {
          borderBottom: "none !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
  },
});

export default responsiveFontSizes(customTheme);
