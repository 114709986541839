import { useState } from "react";
import LoginSignupContainer from "../../components/templates/LoginSignupContainer";
import ResetRequestForm from "./reset_forms/ResetRequestForm";
import ResetOtpForm from "./reset_forms/ResetOtpForm";
import NewPasswordForm from "./reset_forms/NewPasswordForm";
import { Typography } from "@mui/material";

const ForgotPasswordPage = () => {
  const [otp, setOtp] = useState("");
  const [formIndex, setFormIndex] = useState(0);

  const forms = [
    {
      heading: "Forgot Password",
      component: (
        <ResetRequestForm submitCallback={() => setFormIndex(formIndex + 1)} />
      ),
    },
    {
      heading: "OTP Verification",
      component: (
        <ResetOtpForm
          setCompleteOTP={setOtp}
          submitCallback={() => setFormIndex(formIndex + 1)}
        />
      ),
    },
    {
      heading: "New Password",
      component: <NewPasswordForm otp={otp} />,
    },
  ];

  const pageTitle = (
    <Typography component="h2" variant="pageTitle2" marginY={4}>
      {forms[formIndex].heading}
    </Typography>
  );

  return (
    <LoginSignupContainer pageTitle={pageTitle} hideBackButton={true}>
      {forms[formIndex].component}
    </LoginSignupContainer>
  );
};

export default ForgotPasswordPage;
