import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../utils/errorHelper";
import { getIsModuleCompleted } from "../../../../utils/smartCoachHelper";
import SmartCoachController from "../../../../controllers/SmartCoachController";
import BasePage from "../../../BasePage";
import PageBackgroundContainer from "../../../../components/templates/PageBackgroundContainer";
import SimplePopup from "../../../../components/templates/SimplePopup";
import BackgroundImg from "../../../../assets/images/background_6_grouped.png";
import ButtonComp from "../../../../components/base_components/ButtonComp";
import TopicButton from "./TopicButton";

const Completed = ({ ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [topics, setTopics] = useState([]);
  const [completedTopicTitle, setCompletedTopicTitle] = useState("");

  useEffect(() => {
    let mounted = true;

    assignTopicTitle();

    Promise.allSettled([getTopics()])
      .then((responses) => {
        if (mounted) {
          setTopics(filterTopics(responses[0].value));
        }
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
          setShowPopup(true);
        }
      });

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignTopicTitle = () => {
    const state = location.state;

    if (state?.completedTopicTitle?.length > 0) {
      setCompletedTopicTitle(state.completedTopicTitle);
    }
  };

  const getTopics = () => {
    return new Promise(async (res, rej) => {
      try {
        const topicsResponse = await SmartCoachController.getTopics();

        if (!topicsResponse || Object.keys(topicsResponse).length <= 0) {
          enqueueSnackbar(
            "No coaching topics were received from the server. Please try again later",
            { variant: "error" },
          );
          rej();
          return;
        }

        res(topicsResponse);
      } catch (error) {
        const errMsg = getErrorMessage(error);

        // Check if request was aborted
        if (errMsg === null) return;

        enqueueSnackbar(errMsg, { variant: "error" });
        rej(errMsg);
      }
    });
  };

  const filterTopics = (allTopics) => {
    if (!(allTopics?.length > 0)) return [];

    return allTopics.filter((t) => !getIsModuleCompleted(t.status));
  };

  const getNextModuleNum = (topic) => {
    if (
      isNaN(topic?.number_of_modules_completed) ||
      isNaN(topic?.number_of_modules)
    )
      return 0;

    if (topic.number_of_modules_completed >= topic.number_of_modules) return 0;

    return topic.number_of_modules_completed + 1;
  };

  const goToTopic = (topic) => {
    navigate("/smart-coach/topic", { state: { topicId: topic.topic_id } });
  };

  const goToModule = (topic, moduleNum) => {
    navigate("/smart-coach/topic/module", {
      state: { topicId: topic.topic_id, moduleNum: moduleNum },
    });
  };

  const handleForward = () => {
    navigate("/smart-solutions");
  };

  const handleBack = () => {
    navigate("/smart-coach");
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <BasePage>
        <PageBackgroundContainer
          handleBack={handleBack}
          BackgroundImg={BackgroundImg}
          partial
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: { xs: "5rem", sm: 0 },
            }}
          >
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80%",
                }}
              >
                <Typography
                  component="h1"
                  variant="pageTitle1Resp"
                  textAlign="center"
                  gutterBottom
                >
                  Loading The Module Details
                </Typography>
                <CircularProgress size="5rem" />
              </Box>
            )}
            {!isLoading && (
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "800px",
                }}
              >
                {/* Page title */}
                <Typography
                  component="h1"
                  variant="pageTitle2Resp"
                  marginBottom={2}
                >
                  {topics?.length > 0
                    ? "You're on a roll - keep up the momentum!"
                    : "You've done all the topics!"}
                </Typography>

                {/* Page description */}
                <Typography component="h1" variant="body1Resp" marginBottom={3}>
                  {topics?.length > 0
                    ? "Here are some more topics that you might find useful. Select one to start."
                    : "You've completed all the courses - well done! We are working on adding new relevant topics. Check back soon."}
                </Typography>

                {topics?.length > 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: {
                        xs: "column",
                        md: "row",
                      },
                      alignItems: "stretch",
                      justifyContent: "space-evenly",
                      gap: "1rem",
                    }}
                  >
                    {topics.map((topic, i) => (
                      <Box key={i} flex="1" minWidth="250px">
                        <TopicButton
                          title={topic?.title}
                          duration={topic?.duration}
                          status={topic?.status}
                          progress={
                            (topic?.number_of_modules_completed /
                              topic?.number_of_modules) *
                            100
                          }
                          moduleNum={getNextModuleNum(topic)}
                          onClickTopic={() => {
                            goToTopic(topic);
                          }}
                          onClickModule={(e) => {
                            e.stopPropagation();
                            goToModule(topic, getNextModuleNum(topic));
                          }}
                          sx={{ height: "100%" }}
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                <ButtonComp onClick={handleForward} sx={{ marginY: 3 }}>
                  Done
                </ButtonComp>
              </Box>
            )}
          </Box>
        </PageBackgroundContainer>
      </BasePage>

      <SimplePopup
        showPopup={showPopup}
        handleClose={handlePopupClose}
        titleComp={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography component="h5" variant="popupTitle">
              🎉
            </Typography>
            <Typography component="h5" variant="popupTitle">
              Congratulations!
            </Typography>
          </Box>
        }
        contentComp={
          <Typography component="h6" variant="popupContent">
            You have successfully completed the{" "}
            {completedTopicTitle.length > 0 ? (
              <strong>{completedTopicTitle}</strong>
            ) : (
              "previous"
            )}{" "}
            topic. Keep going!
          </Typography>
        }
        actionText="Let's go!"
      />
    </>
  );
};

export default Completed;
