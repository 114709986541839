import { Button } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const ButtonLinkComp = ({ ...props }) => {
  return (
    <Button
      fullWidth
      className="ButtonLink"
      variant="contained"
      endIcon={
        <ExpandCircleDownIcon
          sx={{ transform: "rotate(-90deg)" }}
          fontSize="large"
        />
      }
      {...props}
      sx={{
        ...props.sx,
      }}
    >
      {props.children}
    </Button>
  );
};

export default ButtonLinkComp;
