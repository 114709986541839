import { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useAuthContext } from "../../../contexts/AuthContext";
import { getErrorMessage } from "../../../utils/errorHelper";
import * as Schemas from "../../../utils/validationHelper";
import InputComp from "../../../components/base_components/InputComp";
import ButtonComp from "../../../components/base_components/ButtonComp";
import SimplePopup from "../../../components/templates/SimplePopup";

const NewPasswordForm = ({ otp, ...props }) => {
  const { resetPassword, logout, abortApiRequests } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const initValues = {
    otp: otp,
    new_password: "",
    passwordConfirmation: "",
  };

  const validations = Yup.object({
    new_password: Schemas.getSchemaForPassword(true),
    passwordConfirmation:
      Schemas.getSchemaForPasswordConfirmation("new_password"),
  });

  const [showResultPopup, setShowResultPopup] = useState(false);

  useEffect(() => {
    return () => {
      abortApiRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values, actions) => {
    try {
      actions.setSubmitting(true);

      const response = await resetPassword(values);

      if (response?.message?.toLowerCase() === "success") {
        setShowResultPopup(true);
      } else {
        enqueueSnackbar(
          "An unexpected issue occurred while resetting password. Please try again later",
          { variant: "error" },
        );
      }
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleResultPopupClose = () => {
    setShowResultPopup(false);
    logout(true);
  };

  return (
    <Container sx={{ padding: "0 !important" }}>
      <Formik
        initialValues={initValues}
        validationSchema={validations}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({
          values,
          isSubmitting,
          touched,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              {/* Password input */}
              <Grid item xs={12}>
                <InputComp
                  labelText="Enter your new password"
                  type="password"
                  id="new_password"
                  required
                  showPasswordHint
                  autoComplete="current-password"
                  value={values.new_password}
                  disabled={isSubmitting}
                  touched={touched}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {/* Password confirmation input */}
              <Grid item xs={12}>
                <InputComp
                  labelText="Confirm password"
                  type="password"
                  id="passwordConfirmation"
                  required
                  autoComplete="current-password"
                  value={values.passwordConfirmation}
                  disabled={isSubmitting}
                  touched={touched}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {/* Submit button */}
              <Grid item xs={12} marginY={4}>
                <ButtonComp type="submit" isLoading={isSubmitting}>
                  Change Password
                </ButtonComp>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <SimplePopup
        showPopup={showResultPopup}
        handleClose={handleResultPopupClose}
        titleComp={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "4.6875rem",
              background: "rgba(198, 214, 71, 0.10)",
            }}
          >
            <VerifiedUserIcon
              fontSize="large"
              sx={{ color: "#C6D647", margin: "0.75363rem" }}
            />
            <Typography
              component="h5"
              variant="popupTitle"
              sx={{ color: "#C6D647" }}
            >
              Success
            </Typography>
          </Box>
        }
        contentComp={
          <Typography component="h6" variant="popupContent">
            Your password has been successfully reset.
          </Typography>
        }
        actionText="Login"
      />
    </Container>
  );
};

export default NewPasswordForm;
