import React, { useEffect, useState } from "react";
import { Box, Rating } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  degreesToRadians,
  getCreditScoreRatingBadgeDetails,
} from "../../../utils/generalHelper";
import {
  getDoughnutDefaults,
  getDoughnutWallThickness,
} from "../../../utils/chartjsHelper";
import customTheme from "../../../constants/Theme";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import NudgeWrapper from "../../../components/wrappers/NudgeWrapper";
import BadgeComp from "../../../components/base_components/BadgeComp";

const { cutout, startAngle, circumference } = getDoughnutDefaults();
const chartMaxValue = 1000;
const chartRemainderColor = customTheme.palette.chartColorEmpty.main;
const defaultSegmentDetails = [
  {
    fillColor: customTheme.palette.chartColorVeryBad.main,
    dataValue: 200,
  },
  {
    fillColor: customTheme.palette.chartColorBad.main,
    dataValue: 200,
  },
  {
    fillColor: customTheme.palette.chartColorNeutral.main,
    dataValue: 200,
  },
  {
    fillColor: customTheme.palette.chartColorGood.main,
    dataValue: 200,
  },
  {
    fillColor: customTheme.palette.chartColorVeryGood.main,
    dataValue: 200,
  },
];

const CreditScoreGauge = ({
  userScore,
  ratingValue,
  handleClick,
  handleRatingBadgeClick,
}) => {
  const [chartDetails, setChartDetails] = useState({});
  const [ratingTop, setRatingTop] = useState(0);
  const [ratingBadge, setRatingBadge] = useState({});
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("vs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.down("xl"));

  /**
   * Implement the logic for rendering the chart text.
   *
   * @param chart
   */
  const afterDrawPlugin = (chart) => {
    const {
      ctx,
      chartArea: { width },
    } = chart;

    const ir = width / 2 - getDoughnutWallThickness(width, cutout);
    const { x: xc, y: yc } = chart._metasets[0].data[0];

    ctx.save();

    // Draw inner circle at the center of the graph
    ctx.beginPath();
    ctx.arc(xc, yc, ir - 20, 0, 2 * Math.PI);
    ctx.fillStyle = `${customTheme.palette.secondary.main}40`;
    ctx.fill();
    ctx.closePath();

    // Get the appropriate y-levels
    let yScoreText = yc;
    let yScoreValue = yc;
    let yRatingTop = yc;
    let fontSizeText = customTheme.typography.body1.fontSize;
    let fontSizeValue = customTheme.typography.pageTitle1.fontSize;

    if (isSmallMobile || isMobile || isSmallTablet) {
      yScoreText = yc - 40;
      yScoreValue = yScoreText + 30;
      yRatingTop = yScoreValue + 20;
    } else if (isTablet) {
      yScoreText = yc - 50;
      yScoreValue = yScoreText + 30;
      yRatingTop = yScoreValue + 20;
    } else if (isDesktop) {
      yScoreText = yc - 70;
      yScoreValue = yScoreText + 50;
      yRatingTop = yScoreValue + 40;
      fontSizeText = customTheme.typography.pageTitle2.fontSize;
      fontSizeValue = customTheme.typography.pageTitleVeryLarge.fontSize;
    } else {
      yScoreText = yc - window.innerHeight / 10;
      yScoreValue = yScoreText + 75;
      yRatingTop = yScoreValue + 60;
      fontSizeText = "2.5rem";
      fontSizeValue = "5rem";
    }

    // Draw the text for the center circle
    ctx.fillStyle = customTheme.palette.secondary.main;
    ctx.font = `${customTheme.typography.body1.fontWeight} ${fontSizeText} ${customTheme.typography.fontFamily}`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText("Your Score", xc, yScoreText);

    ctx.font = `700 ${fontSizeValue} ${customTheme.typography.fontFamily}`;
    ctx.fillText(userScore, xc, yScoreValue);

    setRatingTop(yRatingTop);
  };

  /**
   * Setup segment details.
   */
  useEffect(() => {
    if (!userScore && userScore !== 0) return;

    setRatingBadge(getCreditScoreRatingBadgeDetails(userScore));

    // Calculate the chart segment details based on user's score
    let segmentDetails = [];
    let runningScore = 0;
    for (let i = 0; i < defaultSegmentDetails.length; i++) {
      const segment = defaultSegmentDetails[i];

      if (userScore > runningScore + segment.dataValue) {
        // Add the default segment details
        runningScore += segment.dataValue;
        segmentDetails.push({
          fillColor: segment.fillColor,
          dataValue: segment.dataValue,
        });
        continue;
      }

      // Split the remaining chart circumference into two segments
      const activeSegment = {
        fillColor: segment.fillColor,
        dataValue: userScore - runningScore,
      };

      const remainderSegment = {
        fillColor: chartRemainderColor,
        dataValue: chartMaxValue - userScore,
        isFinalSegment: true,
      };

      segmentDetails.push(activeSegment);
      segmentDetails.push(remainderSegment);
      break;
    }

    setChartDetails({
      data: segmentDetails.map((s) => s.dataValue),
      segmentDetails: segmentDetails,
    });
  }, [userScore]);

  /**
   * Creates the gradiant for each segment.
   *
   * @param ctx
   * @returns {CanvasGradient|*}
   */
  const createGradiant = (ctx) => {
    const datasetArcs = ctx.chart._metasets[0].data;
    const { x: xc, y: yc } = datasetArcs[0];

    const segment = chartDetails.segmentDetails[ctx.dataIndex];

    if (!xc || !yc) {
      return segment.fillColor;
    }

    // The remainder segment shouldn't have a gradient
    if (segment.isFinalSegment === true) {
      return segment.fillColor;
    }

    // Calculate starting angle.
    let startingAngle = 360 - startAngle;
    chartDetails.segmentDetails.forEach((segment, i) => {
      if (i < ctx.dataIndex) {
        const segmentAnglePerc = segment.dataValue / chartMaxValue;
        const segmentAngle = circumference * segmentAnglePerc;
        startingAngle += segmentAngle;
      }
    });

    // Create gradient.
    const segmentAnglePerc = segment.dataValue / chartMaxValue;
    const gradient = ctx.chart.ctx.createConicGradient(
      degreesToRadians(startingAngle),
      xc,
      yc,
    );
    gradient.addColorStop(0, `${segment.fillColor}00`);
    gradient.addColorStop(
      (segmentAnglePerc * circumference) / 360,
      segment.fillColor,
    );
    gradient.addColorStop(1, "transparent");

    return gradient;
  };

  return (
    <Box
      onClick={handleClick ? handleClick : null}
      sx={{
        width: "100%",
        maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        cursor: handleClick ? "pointer" : "default",
      }}
    >
      <DoughnutChart
        data={chartDetails.data}
        plugins={[
          {
            afterDraw: afterDrawPlugin,
          },
        ]}
        optionsOverride={{
          elements: {
            arc: {
              backgroundColor: (ctx) => {
                return createGradiant(ctx);
              },
            },
          },
        }}
      />
      {ratingTop > 0 && (
        <Box
          onClick={handleRatingBadgeClick ? handleRatingBadgeClick : null}
          sx={{
            position: "absolute",
            top: ratingTop,
            cursor: handleRatingBadgeClick ? "pointer" : "default",
          }}
        >
          <NudgeWrapper
            id="nudge-overview-1"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Rating
              id="nudge-overview-1"
              readOnly
              value={ratingValue}
              size={isMobile || isTablet ? "small" : "medium"}
            />
            <BadgeComp
              icon={ratingBadge.iconResp}
              description={ratingBadge.descriptionShort}
              backgroundColor={ratingBadge.backgroundColor}
              reduceSize
            />
          </NudgeWrapper>
        </Box>
      )}
    </Box>
  );
};

export default CreditScoreGauge;
