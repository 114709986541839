import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

const IconListComp = ({ items, ...props }) => {
  if (!items || items.length <= 0) return null;

  return (
    <List>
      {items.map((item, i) => (
        <ListItem key={i}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primary={item.text}
            sx={{
              color: props?.textColor ? props.textColor : "black",
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default IconListComp;
