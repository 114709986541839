import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  currencyFormatter,
  formatDate,
  getEmptyFolderCard,
  normaliseString,
  overrideTypographyStyle,
} from "../../../utils/generalHelper";
import TaskIcon from "@mui/icons-material/Task";
import AccordionComp from "../../../components/base_components/AccordionComp";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import BalanceIcon from "@mui/icons-material/Balance";
import DescriptionIcon from "@mui/icons-material/Description";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

const OtherListingsTab = ({ otherListings, accordionSx }) => {
  const debtReviews = otherListings.debt_reviews;
  const judgements = otherListings.judgements;
  const debtCollections = otherListings.debt_collections;
  const adverseListings = otherListings.adverse_listings;
  const debtOrders = otherListings.debt_orders;
  const inquiries = otherListings.inquiries;

  const renderGroup = (groupName, groupIcon, groupEmptyText, items) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginY: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          {groupIcon}
          <Typography component="span" variant="body1BoldResp" ml={1}>
            {normaliseString(groupName)}
          </Typography>
        </Box>

        {items.length === 0 && getEmptyFolderCard(groupEmptyText)}

        {items}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        paddingBottom: { xs: "5rem", sm: 0 },
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          {/* Debt Reviews */}
          {renderGroup(
            "Debt Reviews",
            <TaskIcon color="brandedTeal" />,
            "You are not under debt review",
            debtReviews.map((debtReview, i) => (
              <AccordionComp
                key={i}
                sx={accordionSx(i, debtReviews.length)}
                headerOverride={
                  <Box>
                    <Typography
                      component="h6"
                      gutterBottom
                      sx={{
                        ...overrideTypographyStyle("body1"),
                      }}
                    >
                      Counsellor
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        ...overrideTypographyStyle("body1Bold"),
                      }}
                    >
                      {normaliseString(
                        `${debtReview.counsellor_first_name} ${debtReview.counsellor_last_name}`,
                      ).trim()}
                    </Typography>
                  </Box>
                }
                contentItems={[
                  {
                    registration_nr: {
                      fullWidth: false,
                      value: debtReview.counsellor_registration_number || "-",
                    },
                    contact_nr: {
                      fullWidth: false,
                      value: debtReview.counsellor_contact_number || "-",
                    },
                    status_date: {
                      fullWidth: false,
                      value:
                        formatDate(debtReview.status_date) || "No date set",
                    },
                    status_code: {
                      fullWidth: false,
                      value: debtReview.debt_review_status_code || "-",
                    },
                    status: {
                      fullWidth: true,
                      value: normaliseString(
                        debtReview.debt_review_status || "-",
                      ).trim(),
                    },
                  },
                ]}
              />
            )),
          )}

          {/* Debt Collections */}
          {renderGroup(
            "Debt Collections",
            <MoneyOffIcon color="brandedTeal" />,
            "You have no debt collections",
            debtCollections.map((debtCollection, i) => (
              <AccordionComp
                key={i}
                sx={accordionSx(i, debtCollections.length)}
                headerOverride={
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        component="h6"
                        sx={{
                          ...overrideTypographyStyle("body1"),
                        }}
                      >
                        Account Number
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          ...overrideTypographyStyle("body1Bold"),
                        }}
                      >
                        {debtCollection.account_no}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        component="h6"
                        sx={{
                          ...overrideTypographyStyle("body1"),
                        }}
                      >
                        Handover Date
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          ...overrideTypographyStyle("body1Bold"),
                        }}
                      >
                        {formatDate(debtCollection.handover_date) ||
                          "No date set"}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                contentItems={[
                  {
                    name: {
                      fullWidth: true,
                      value: normaliseString(debtCollection.name).trim() || "-",
                    },
                    current_balance: {
                      fullWidth: false,
                      value: currencyFormatter.format(
                        debtCollection.current_balance || 0,
                      ),
                    },
                    installment_amount: {
                      fullWidth: false,
                      value: currencyFormatter.format(
                        debtCollection.installment_amount || 0,
                      ),
                    },
                    amount_overdue: {
                      fullWidth: false,
                      value: currencyFormatter.format(
                        debtCollection.amount_overdue || 0,
                      ),
                    },
                  },
                ]}
              />
            )),
          )}

          {/* Judgements */}
          {renderGroup(
            "Judgements",
            <BalanceIcon color="brandedTeal" />,
            "You have no judgements against your name",
            judgements.map((judgement, i) => (
              <AccordionComp
                key={i}
                sx={accordionSx(i, judgements.length)}
                headerOverride={
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        component="h6"
                        sx={{
                          ...overrideTypographyStyle("body1"),
                        }}
                      >
                        Case Number
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          ...overrideTypographyStyle("body1Bold"),
                        }}
                      >
                        {judgement.case_number}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        component="h6"
                        sx={{
                          ...overrideTypographyStyle("body1"),
                        }}
                      >
                        Case Type
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          ...overrideTypographyStyle("body1Bold"),
                        }}
                      >
                        {normaliseString(judgement.case_type).trim()}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                contentItems={[
                  {
                    plaintiff: {
                      fullWidth: false,
                      value: normaliseString(judgement.plaintiff).trim() || "-",
                    },
                    case_reason: {
                      fullWidth: false,
                      value:
                        normaliseString(judgement.case_reason).trim() || "-",
                    },
                    amount: {
                      fullWidth: true,
                      value: currencyFormatter.format(judgement.amount || 0),
                    },
                    granted_date: {
                      fullWidth: true,
                      value: formatDate(judgement.granted) || "No date set",
                    },
                    attorney_name: {
                      fullWidth: false,
                      value:
                        normaliseString(judgement.attorney_name).trim() || "-",
                    },
                    attorney_telephone: {
                      fullWidth: false,
                      value:
                        normaliseString(
                          judgement.attorney_office_number,
                        ).trim() || "-",
                    },
                    court_name: {
                      fullWidth: false,
                      value:
                        normaliseString(judgement.court_name).trim() || "-",
                    },
                    court_type: {
                      fullWidth: false,
                      value:
                        normaliseString(judgement.court_type).trim() || "-",
                    },
                  },
                ]}
              />
            )),
          )}

          {/* Adverse Listings */}
          {renderGroup(
            "Adverse Listings",
            <NoteAddIcon color="brandedTeal" />,
            "There are no adverse items against your name",
            adverseListings.map((adverseListing, i) => (
              <AccordionComp
                key={i}
                sx={accordionSx(i, adverseListings.length)}
                headerOverride={
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        component="h6"
                        sx={{
                          ...overrideTypographyStyle("body1"),
                        }}
                      >
                        Name
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          ...overrideTypographyStyle("body1Bold"),
                        }}
                      >
                        {normaliseString(adverseListing.name)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        component="h6"
                        sx={{
                          ...overrideTypographyStyle("body1"),
                        }}
                      >
                        Adverse Date
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          ...overrideTypographyStyle("body1Bold"),
                        }}
                      >
                        {formatDate(adverseListing.adverse_date) ||
                          "No date set"}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                contentItems={[
                  {
                    account_number: {
                      fullWidth: false,
                      value: adverseListing.account_no || "-",
                    },
                    date_account_opened: {
                      fullWidth: false,
                      value:
                        formatDate(adverseListing.date_account_opened) ||
                        "No date set",
                    },
                    current_balance: {
                      fullWidth: false,
                      value: currencyFormatter.format(
                        adverseListing.current_balance || 0,
                      ),
                    },
                    installment_amount: {
                      fullWidth: false,
                      value: currencyFormatter.format(
                        adverseListing.installment_amount || 0,
                      ),
                    },
                  },
                ]}
              />
            )),
          )}

          {/* Administrations / Sequestration */}
          {renderGroup(
            "Administrations / Sequestrations",
            <DescriptionIcon color="brandedTeal" />,
            "You are not under administration / sequestration",
            debtOrders.map((debtOrder, i) => (
              <AccordionComp
                key={i}
                sx={accordionSx(i, debtOrders.length)}
                headerOverride={
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        component="h6"
                        sx={{
                          ...overrideTypographyStyle("body1"),
                        }}
                      >
                        Case Number
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          ...overrideTypographyStyle("body1Bold"),
                        }}
                      >
                        {debtOrder.case_number}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        component="h6"
                        sx={{
                          ...overrideTypographyStyle("body1"),
                        }}
                      >
                        Case Type
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          ...overrideTypographyStyle("body1Bold"),
                        }}
                      >
                        {normaliseString(debtOrder.case_type).trim()}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                contentItems={[
                  {
                    case_filing_date: {
                      fullWidth: false,
                      value:
                        formatDate(debtOrder.case_filing_date) || "No date set",
                    },
                    case_reason: {
                      fullWidth: false,
                      value:
                        normaliseString(debtOrder.case_reason).trim() || "-",
                    },
                    amount: {
                      fullWidth: true,
                      value: currencyFormatter.format(debtOrder.amount || 0),
                    },
                    attorney_name: {
                      fullWidth: false,
                      value:
                        normaliseString(debtOrder.attorney_name).trim() || "-",
                    },
                    attorney_telephone: {
                      fullWidth: false,
                      value: debtOrder.attorney_office_telephone_number || "-",
                    },
                    court_name: {
                      fullWidth: false,
                      value:
                        normaliseString(debtOrder.court_name).trim() || "-",
                    },
                    court_city: {
                      fullWidth: false,
                      value:
                        normaliseString(debtOrder.court_city).trim() || "-",
                    },
                    court_type: {
                      fullWidth: false,
                      value:
                        normaliseString(debtOrder.court_type).trim() || "-",
                    },
                    court_case_id: {
                      fullWidth: false,
                      value:
                        normaliseString(debtOrder.court_case_id).trim() || "-",
                    },
                  },
                ]}
              />
            )),
          )}
        </Grid>

        {/* Inquiries */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginY: 2,
            }}
          >
            {/* Group header & icon */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
              }}
            >
              <ManageSearchIcon color="brandedTeal" />
              <Typography component="span" variant="body1BoldResp" ml={1}>
                {normaliseString("inquiries")}
              </Typography>
            </Box>

            {inquiries.length === 0 &&
              getEmptyFolderCard("You have no inquiries")}

            {inquiries.length > 0 && (
              <TableContainer
                style={{
                  maxHeight: 500,
                  overflowY: "auto",
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1Resp">
                          Company Name
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1Resp">Search Date</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inquiries.map((listing, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body1BoldResp">
                            {normaliseString(listing.company_name).trim()}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1BoldResp">
                            {formatDate(listing.search_date) || "No date set"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OtherListingsTab;
