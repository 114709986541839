import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ButtonComp from "../../../components/base_components/ButtonComp";
import { useContext } from "react";
import { FullscreenContext } from "../../../contexts/FullscreenContext";

const SignupCompleted = ({ ...props }) => {
  const navigate = useNavigate();
  const { openFullscreen } = useContext(FullscreenContext);

  const redirectToOverview = () => {
    openFullscreen();
    navigate("/overview");
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transform: "translateY(-40px)",
        }}
      >
        <Typography component="h1" variant="pageTitle1" textAlign="center">
          Congratulations! 🎉
        </Typography>

        <Typography
          component="p"
          variant="body1"
          textAlign="center"
          marginY={4}
        >
          You have successfully registered a profile with ThreeSixty.me
        </Typography>

        <ButtonComp onClick={redirectToOverview}>
          Continue to credit profile
        </ButtonComp>
      </Box>
    </>
  );
};

export default SignupCompleted;
