import { Box, Paper, Typography } from "@mui/material";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import HomeIcon from "@mui/icons-material/Home";
import WeekendIcon from "@mui/icons-material/Weekend";
import SecurityIcon from "@mui/icons-material/Security";
import customTheme from "../../../../constants/Theme";
import ButtonComp from "../../../../components/base_components/ButtonComp";

const ShortTermInsuranceTab = ({ callToAction, details, ...props }) => {
  return (
    <Box>
      <Typography component="h2" variant="pageTitle2" mt="1rem" mb="2rem">
        Get Insurance
      </Typography>

      <Typography component="p" variant="body1Resp">
        We work with all major insurers to give you quality cover for the best
        price!
      </Typography>

      <Box
        my={2}
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "stretch",
          justifyContent: "space-evenly",
          gap: "1rem",
        }}
      >
        {/* Vehicle insurance info card */}
        <Box
          flex="1"
          minWidth="300px"
          display={!details?.show_vehicle_insurance ? "none" : "flex"}
        >
          <Paper
            className="infoBubble"
            sx={{
              flexDirection: "column",
              justifyContent: "space-between !important",
              padding: "1rem !important",
            }}
          >
            <Box display="flex" alignItems="center">
              <CarCrashIcon fontSize="medium" />
              <Typography component="span" variant="pageTitle2SmallResp" ml={1}>
                Vehicle Insurance
              </Typography>
            </Box>

            <Typography component="p" variant="body1Resp" mt={2}>
              We noticed you are a car owner! Secure your wheels with our
              affordable vehicle coverage offering!
            </Typography>

            <Typography component="p" variant="body1Resp" mt={1}>
              We partner with top insurers that give competitive rates. Let’s
              explore affordable premiums together.
            </Typography>

            <Typography
              component="p"
              variant="body1BoldResp"
              marginY={2}
              sx={{
                color: customTheme.palette.textVeryGood.main,
                width: "100%",
              }}
            >
              Your wheels, your affordability!
            </Typography>

            <ButtonComp
              onClick={() => {
                callToAction("vehicle_insurance");
              }}
              sx={{
                minWidth: "120px",
                width: "40%",
                alignSelf: "flex-end",
              }}
            >
              Call me
            </ButtonComp>
          </Paper>
        </Box>

        {/* Buildinge insurance info card */}
        <Box
          flex="1"
          minWidth="300px"
          display={!details?.show_building_insurance ? "none" : "flex"}
        >
          <Paper
            className="infoBubble"
            sx={{
              flexDirection: "column",
              justifyContent: "space-between !important",
              padding: "1rem !important",
            }}
          >
            <Box display="flex" alignItems="center">
              <HomeIcon fontSize="medium" />
              <Typography component="span" variant="pageTitle2SmallResp" ml={1}>
                Building Insurance
              </Typography>
            </Box>

            <Typography component="p" variant="body1Resp" mt={2}>
              Protect your home sweet home with our budget-friendly and
              quality-approved building insurance! Whether it’s the bricks or
              structures in-between, we’ve got you covered.
            </Typography>

            <Typography component="p" variant="body1Resp" mt={1}>
              Explore affordable building insurance rates through our trusted
              partners.
            </Typography>

            <Typography
              component="p"
              variant="body1BoldResp"
              marginY={2}
              sx={{
                color: customTheme.palette.textVeryGood.main,
                width: "100%",
              }}
            >
              Because every inch of your property deserves protection.
            </Typography>

            <ButtonComp
              onClick={() => {
                callToAction("building_insurance");
              }}
              sx={{
                minWidth: "120px",
                width: "40%",
                alignSelf: "flex-end",
              }}
            >
              Call me
            </ButtonComp>
          </Paper>
        </Box>

        {/* Home contents insurance info card */}
        <Box
          flex="1"
          minWidth="300px"
          display={!details?.show_home_content_insurance ? "none" : "flex"}
        >
          <Paper
            className="infoBubble"
            sx={{
              flexDirection: "column",
              justifyContent: "space-between !important",
              padding: "1rem !important",
            }}
          >
            <Box display="flex" alignItems="center">
              <WeekendIcon fontSize="medium" />
              <Typography component="span" variant="pageTitle2SmallResp" ml={1}>
                Home Contents Cover
              </Typography>
            </Box>

            <Typography component="p" variant="body1Resp" mt={2}>
              Secure your home contents and valuables with us!
            </Typography>

            <Typography component="p" variant="body1Resp" mt={1}>
              With our top insurers’ competitive rates, you can protect what
              matters most at a great price.
            </Typography>

            <Typography
              component="p"
              variant="body1BoldResp"
              marginY={2}
              sx={{
                color: customTheme.palette.textVeryGood.main,
                width: "100%",
              }}
            >
              Protect what matters most!
            </Typography>

            <ButtonComp
              onClick={() => {
                callToAction("home_content_insurance");
              }}
              sx={{
                minWidth: "120px",
                width: "40%",
                alignSelf: "flex-end",
              }}
            >
              Call me
            </ButtonComp>
          </Paper>
        </Box>

        {/* General insurance info card */}
        <Box
          flex="1"
          minWidth="300px"
          display={!details?.show_general_insurance ? "none" : "flex"}
        >
          <Paper
            className="infoBubble"
            sx={{
              flexDirection: "column",
              justifyContent: "space-between !important",
              padding: "1rem !important",
            }}
          >
            <Box display="flex" alignItems="center">
              <SecurityIcon fontSize="medium" />
              <Typography component="span" variant="pageTitle2SmallResp" ml={1}>
                All Risk Insurance (specified and unspecified)
              </Typography>
            </Box>

            <Typography component="p" variant="body1Resp" mt={2}>
              Protect more than just your car or house! Our All Risk Insurance
              option covers from everyday valuables like your cellphones,
              laptops, jewellery to unspecified items such as personal clothing,
              a handbag or your child’s schoolbag.
            </Typography>

            <Typography component="p" variant="body1Resp" mt={1}>
              Let’s find the perfect coverage for your valuables.
            </Typography>

            <Typography
              component="p"
              variant="body1BoldResp"
              marginY={2}
              sx={{
                color: customTheme.palette.textVeryGood.main,
                width: "100%",
              }}
            >
              Competitive rates with partners you can TRUST.
            </Typography>

            <ButtonComp
              onClick={() => {
                callToAction("general_insurance");
              }}
              sx={{
                minWidth: "120px",
                width: "40%",
                alignSelf: "flex-end",
              }}
            >
              Call me
            </ButtonComp>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default ShortTermInsuranceTab;
