import AccordionComp from "../../../components/base_components/AccordionComp";
import { formatDate, normaliseString } from "../../../utils/generalHelper";
import { Box, Typography } from "@mui/material";
import customTheme from "../../../constants/Theme";

const AccountClosedComp = ({ account, i, groupLength, accordionSx }) => {
  return (
    <AccordionComp
      key={i}
      sx={accordionSx(i, groupLength)}
      headerOverride={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: {
                xs: "0.875rem !important",
                sm: "1rem !important",
              },
              fontWeight: {
                xs: "400 !important",
                sm: "600 !important",
              },
              paddingRight: "0.5rem",
            }}
          >
            {normaliseString(account.name)}
          </Typography>
          <Box
            sx={{
              marginRight: {
                xs: "0.5rem",
                lg: "1rem",
              },
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                lg: "row",
              },
              alignItems: {
                xs: "flex-end",
                lg: "center",
              },
              justifyContent: {
                xs: "center",
                lg: "space-between",
              },
              minWidth: {
                xs: "100px",
                sm: "140px",
                lg: "unset",
              },
            }}
          >
            <Box
              sx={{
                padding: { xs: "3px 7px", sm: "5px 10px" },
                borderRadius: "0.5rem",
                backgroundColor:
                  customTheme.palette.chartColorNeutral.background,
                color: customTheme.palette.chartColorNeutral.main,
              }}
            >
              <Typography
                component="span"
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: {
                    xs: "0.6rem !important",
                    sm: "0.875rem !important",
                  },
                  fontWeight: "400 !important",
                }}
              >
                {normaliseString(account.account_status).trim()}
              </Typography>
            </Box>
            <Typography
              component="span"
              textAlign="right"
              sx={{
                whiteSpace: "nowrap",
                fontSize: {
                  xs: "0.8rem !important",
                  sm: "1rem !important",
                },
                fontWeight: {
                  xs: "400 !important",
                  sm: "600 !important",
                },
                minWidth: {
                  xs: "unset",
                  lg: "140px",
                },
                marginBottom: {
                  xs: "0.5rem",
                  lg: 0,
                },
              }}
            ></Typography>
          </Box>
        </Box>
      }
      contentItems={[
        {
          name: {
            fullWidth: false,
            value: normaliseString(account.name).trim(),
          },
          account_number: {
            fullWidth: false,
            value: normaliseString(account.account_no).trim(),
          },
          status: {
            fullWidth: false,
            value: normaliseString(account.account_status).trim(),
          },
          status_date: {
            fullWidth: false,
            value: formatDate(account.account_status_date) || "No date set",
          },
        },
      ]}
    />
  );
};

export default AccountClosedComp;
