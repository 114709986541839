import React, { createContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

const FullscreenContext = createContext();

const fullscreenButtonEnabled =
  process.env.REACT_APP_ENABLE_FULLSCREEN_BUTTON.toLowerCase() === "true";

function FullscreenContextProvider(props) {
  const [fullscreen, setFullscreen] = useState(false);

  /**
   * Add fullscreenchange listener.
   */
  useEffect(() => {
    if (!fullscreenButtonEnabled) {
      return;
    }

    function onFullscreenChange() {
      setFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  /**
   * Toggle fullscreen.
   */
  const toggleFullscreen = () => {
    if (fullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  /**
   * Returns true if fullscreen is available and enabled.
   *
   * @returns {false|((options?: FullscreenOptions) => Promise<void>)}
   */
  const hasFullscreen = () => {
    return (
      fullscreenButtonEnabled &&
      Boolean(document.fullscreenEnabled) &&
      Boolean(document.exitFullscreen) &&
      Boolean(document.documentElement.requestFullscreen)
    );
  };

  /**
   * Opens up fullscreen if it is available.
   */
  const openFullscreen = () => {
    try {
      if (isMobile && hasFullscreen()) {
        document.documentElement.requestFullscreen();
      }
    } catch (e) {
      // Do nothing.
    }
  };

  return (
    <FullscreenContext.Provider
      {...props}
      value={{
        fullscreen,
        toggleFullscreen,
        hasFullscreen,
        openFullscreen,
      }}
    />
  );
}

export { FullscreenContext, FullscreenContextProvider };
