import { getNextModuleStatus } from "../utils/smartCoachHelper";
import BaseController from "./BaseController";

class Controller extends BaseController {
  constructor() {
    super();
    this.TopicIdPlaceholder = "{topic_id}";
    this.ModuleIdPlaceholder = "{module_id}";
    this.TopicsURL = "/smart-coach/topics";
    this.TopicDetailsURL = `/smart-coach/topics/${this.TopicIdPlaceholder}`;
    this.ModuleDetailsURL = `/smart-coach/modules/${this.ModuleIdPlaceholder}`;
  }

  getTopics = () => {
    return this.sendRequest("GET", this.TopicsURL);
  };

  getTopicDetails = (topicId) => {
    return this.sendRequest(
      "GET",
      this.TopicDetailsURL.replace(this.TopicIdPlaceholder, topicId),
    );
  };

  getModuleDetails = (moduleId) => {
    return this.sendRequest(
      "GET",
      this.ModuleDetailsURL.replace(this.ModuleIdPlaceholder, moduleId),
    );
  };

  saveModuleStatus = (moduleId, moduleStatus) => {
    const requestBody = {
      status: moduleStatus,
    };

    return this.sendRequest(
      "POST",
      this.ModuleDetailsURL.replace(this.ModuleIdPlaceholder, moduleId),
      null,
      requestBody,
    );
  };

  updateModuleStatus = (moduleId, currentModuleStatus) => {
    const nextStatus = getNextModuleStatus(currentModuleStatus);
    const requestBody = {
      status: nextStatus,
    };

    return this.sendRequest(
      "POST",
      this.ModuleDetailsURL.replace(this.ModuleIdPlaceholder, moduleId),
      null,
      requestBody,
    );
  };

  saveModule = (moduleId) => {
    const requestBody = {
      status: "in_progress",
    };

    return this.sendRequest(
      "POST",
      this.ModuleDetailsURL.replace(this.ModuleIdPlaceholder, moduleId),
      null,
      requestBody,
    );
  };

  completeModule = (moduleId) => {
    const requestBody = {
      status: "completed",
    };

    return this.sendRequest(
      "POST",
      this.ModuleDetailsURL.replace(this.ModuleIdPlaceholder, moduleId),
      null,
      requestBody,
    );
  };
}

const SmartCoachController = new Controller();

export default SmartCoachController;
