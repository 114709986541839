import { useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useAuthContext } from "../../../contexts/AuthContext";
import { getErrorMessage } from "../../../utils/errorHelper";
import { getSchemaForID } from "../../../utils/validationHelper";
import InputComp from "../../../components/base_components/InputComp";
import ButtonComp from "../../../components/base_components/ButtonComp";

const ResetRequestForm = ({ submitCallback, ...props }) => {
  const { requestPasswordReset, abortApiRequests } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const initValues = {
    id_number: "",
  };

  const validations = Yup.object({
    id_number: getSchemaForID(true),
  });

  useEffect(() => {
    return () => {
      abortApiRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values, actions) => {
    try {
      actions.setSubmitting(true);

      const response = await requestPasswordReset(values);

      if (response?.message === "success") {
        if (submitCallback) submitCallback();
      } else {
        enqueueSnackbar(
          "An unexpected issue occurred while requesting password reset. Please try again later",
        );
      }
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Container sx={{ padding: "0 !important" }}>
      <Formik
        initialValues={initValues}
        validationSchema={validations}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({
          values,
          isSubmitting,
          touched,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="p" variant="body1" textAlign="center">
                  Don’t worry! Please enter your ID number linked with your
                  account
                </Typography>
              </Grid>

              {/* ID number input */}
              <Grid item xs={12}>
                <InputComp
                  labelText="Identification number (ID)"
                  type="text"
                  id="id_number"
                  required
                  value={values.id_number}
                  disabled={isSubmitting}
                  touched={touched}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {/* Submit button */}
              <Grid item xs={12} marginY={2}>
                <ButtonComp type="submit" isLoading={isSubmitting}>
                  Reset Password
                </ButtonComp>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ResetRequestForm;
