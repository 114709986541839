import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useAuthContext } from "../../../contexts/AuthContext";
import { getErrorMessage } from "../../../utils/errorHelper";
import {
  getSchemaForOTP,
  validateOtpChars,
} from "../../../utils/validationHelper";
import SignUpController from "../../../controllers/SignUpController";
import ButtonComp from "../../../components/base_components/ButtonComp";
import LinkComp from "../../../components/base_components/LinkComp";
import ErrorMsgComp from "../../../components/base_components/ErrorMsgComp";
import ResendOtpForm from "./ResendOtpForm";

const OtpVerificationForm = ({
  submitCallback,
  sendOtpOnLoad,
  currentMobile,
  ...props
}) => {
  const { validateOTP, sendOTP, resendOTP, logout, abortApiRequests } =
    useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const otpLength = 4;
  const otpSchema = getSchemaForOTP(otpLength);

  const [otp, setOtp] = useState("");
  const [isValidOTP, setIsValidOTP] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidatingStatus, setIsValidatingStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showResendOtpForm, setShowResendOtpForm] = useState(false);

  useEffect(() => {
    if (sendOtpOnLoad === true) handleSendOtp();

    return () => {
      abortApiRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleComplete = (value) => {
    otpSchema
      .isValid(value)
      .then((valid) => setIsValidOTP(valid))
      .catch((err) => setIsValidOTP(false));
  };

  const handleSubmit = async (event) => {
    if (event.preventDefault) event.preventDefault();

    // Submit the OTP for verification
    try {
      setIsSubmitting(true);

      const otpResponseMsg = await validateOTP(otp);

      if (otpResponseMsg && otpResponseMsg !== "success") {
        setErrorMessage(otpResponseMsg);
        return;
      }

      // The OTP is successfully verified, check if user needs to complete security questions
      setIsValidatingStatus(true);
      await checkStatus();
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
    } finally {
      setIsSubmitting(false);
      setIsValidatingStatus(false);
    }
  };

  const checkStatus = async () => {
    const statusResponse = await SignUpController.checkStatus();

    if (!statusResponse.isReportPulled) {
      setErrorMessage(
        statusResponse.errorMsg ||
          "Account status could not be verified. Please try again later.",
      );

      // Reset session token immediately
      logout(false);
      setTimeout(() => {
        // Return to parent indicating that the report was not pulled
        submitCallback(false, true);
      }, 5000);
    } else {
      // Credit report was pulled, proceed to security questions if the account is not verified
      submitCallback(!statusResponse.isAccountVerified);
    }
  };

  const handleSendOtp = async (mobile = null) => {
    try {
      setIsSubmitting(true);

      await sendOTP(mobile);

      enqueueSnackbar("OTP was sent to your mobile number", {
        variant: "info",
      });
      setShowResendOtpForm(false);
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      // Check if the mobile is already in use
      if (errMsg?.toLowerCase()?.includes("already in use")) {
        enqueueSnackbar("Mobile is already in use", {
          variant: "warning",
        });
        return;
      }

      enqueueSnackbar(errMsg, { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendOtp = async (event) => {
    if (event?.preventDefault) event.preventDefault();

    try {
      setIsSubmitting(true);

      await resendOTP();

      enqueueSnackbar("OTP was sent to your mobile number", {
        variant: "info",
      });
      setShowResendOtpForm(false);
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showResendOtpForm) {
    return (
      <ResendOtpForm
        currentMobile={currentMobile}
        handleSendOtp={handleSendOtp}
        handleResendOtp={handleResendOtp}
      ></ResendOtpForm>
    );
  }

  return (
    /* OTP verification form components */
    <Container sx={{ padding: "0 !important" }}>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Grid item xs={12}>
          <Typography component="p" variant="body1" textAlign="center">
            Please enter the 4 digit code we just sent via SMS
          </Typography>
        </Grid>

        <Grid item xs={12} marginY={3} container justifyContent="center">
          <MuiOtpInput
            id="otp"
            autoFocus
            length={otpLength}
            value={otp}
            disabled={isSubmitting}
            validateChar={validateOtpChars}
            onChange={handleChange}
            onComplete={handleComplete}
            sx={{ maxWidth: "284px" }}
            TextFieldsProps={{
              inputProps: { inputMode: "numeric" },
            }}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <ButtonComp
            type="submit"
            disabled={isSubmitting || !isValidOTP}
            isLoading={isSubmitting}
          >
            Verify
          </ButtonComp>
        </Grid>

        {/* Error message */}
        {errorMessage && (
          <Grid item xs={12} container justifyContent="center">
            <ErrorMsgComp errorMessage={errorMessage} />
          </Grid>
        )}

        <Grid item xs={12} container justifyContent="center" marginTop={4}>
          <Box component="span" sx={{ display: "flex", width: "fit-content" }}>
            <Typography
              variant="body2"
              component="span"
              sx={{ marginRight: "10px" }}
            >
              Didn't get a code?
            </Typography>
            <LinkComp
              href="#"
              onClick={() => setShowResendOtpForm(true)}
              disabled={isSubmitting}
            >
              Resend code
            </LinkComp>
          </Box>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ width: { xs: "100vw", md: "50vw" } }}
        open={isValidatingStatus}
      >
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="PageTitle2"
              textAlign="center"
              gutterBottom
            >
              OTP Successful, Validating Status
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop={5}>
            <CircularProgress size="5rem" />
          </Grid>
        </Grid>
      </Backdrop>
    </Container>
  );
};

export default OtpVerificationForm;
