import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  getEmptyFolderCard,
  normaliseString,
} from "../../../utils/generalHelper";
import PaymentHistoryGrid from "./PaymentHistoryGrid";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import customTheme from "../../../constants/Theme";
import ErrorIcon from "@mui/icons-material/Error";

const PaymentHistoryTab = ({ accounts }) => {
  /**
   * Render account group.
   *
   * @param groupName
   * @param groupAccounts
   * @param emptyFolderText
   * @returns {JSX.Element}
   */
  const renderGroup = (groupName, groupAccounts, emptyFolderText) => {
    const groupStatusPositive =
      groupAccounts.reduce(
        (acc, account) => acc + account.amount_overdue,
        0,
      ) === 0;
    return (
      <Box sx={{ width: "100%", marginY: 2 }}>
        <Box sx={{ width: "100%" }}>
          {/* Group header */}
          <Box
            sx={{
              width: "100%",
              paddingY: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {groupStatusPositive ? (
                <VerifiedUserIcon
                  fontSize="large"
                  sx={{
                    color: customTheme.palette.textGood.main,
                    marginRight: {
                      xs: "0.5rem",
                      sm: "1rem",
                    },
                  }}
                />
              ) : (
                <ErrorIcon
                  fontSize="large"
                  sx={{
                    color: customTheme.palette.textBad.main,
                    marginRight: {
                      xs: "0.5rem",
                      sm: "1rem",
                    },
                  }}
                />
              )}
              <Typography component="span" variant="body1BoldResp">
                {normaliseString(groupName)}
              </Typography>
            </Box>
          </Box>

          {/* Empty group */}
          {groupAccounts.length === 0 && getEmptyFolderCard(emptyFolderText)}

          {/* Payment histories */}
          {groupAccounts.map((account, i) => (
            <Box key={i}>
              {i === 0 ? null : <Divider sx={{ marginY: "1rem" }} />}
              <Grid container spacing={2} direction="column">
                {/* Account name */}
                <Grid item xs={12}>
                  <Typography component="h6" variant="body1Resp">
                    {normaliseString(account.name)}
                  </Typography>
                </Grid>

                {/* Payment history */}
                <Grid item xs={12} container>
                  <PaymentHistoryGrid account={account} />
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {/* Unsecured */}
      {renderGroup(
        "Unsecured",
        accounts.filter((account) => account.grouping === "unsecured"),
        "You have no Unsecured accounts",
      )}

      {/* Vehicles */}
      {renderGroup(
        "Vehicles",
        accounts.filter((account) => account.grouping === "vehicles"),
        "You have no Vehicle accounts",
      )}

      {/* Home-Loans */}
      {renderGroup(
        "Home-Loans",
        accounts.filter((account) => account.grouping === "home-loans"),
        "You have no Home Loan accounts",
      )}

      {/* Service-Providers */}
      {renderGroup(
        "Service-Providers",
        accounts.filter((account) => account.grouping === "service-providers"),
        "You have no Service Provider accounts",
      )}

      {/* Insurance */}
      {renderGroup(
        "Insurance",
        accounts.filter((account) => account.grouping === "insurance"),
        "You have no Insurance accounts",
      )}
    </Box>
  );
};

export default PaymentHistoryTab;
