import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { logPageView } from "./utils/analyticsHelper.js";
import NudgeContextProvider from "./contexts/NudgeContext";
import LoginPage from "./pages/login/LoginPage";
import SignUpPage from "./pages/signup/SignUpPage";
import ForgotPasswordPage from "./pages/password_reset/ForgotPasswordPage";
import OverviewPage from "./pages/overview/OverviewPage";
import ContactUsPage from "./pages/contact_us/ContactUsPage";
import ProfilePage from "./pages/profile/ProfilePage";
import PersonalInformationForm from "./pages/profile/forms/PersonalInformationForm";
import ChangePasswordRequest from "./pages/profile/forms/ChangePasswordForm";
import PersonalAddressForm from "./pages/profile/forms/PersonalAddressForm";
import SmartHelpPage from "./pages/smart_help/SmartHelpPage";
import SmartCoachPage from "./pages/smart_help/smart_coach/SmartCoachPage.jsx";
import SmartSolutionsPage from "./pages/smart_help/smart_solutions/SmartSolutionsPage";
import CoachContent from "./pages/smart_help/smart_coach/components/CoachContent.jsx";
import Completed from "./pages/smart_help/smart_coach/components/Completed.jsx";
import LogDisputePage from "./pages/log_dispute/LogDisputePage";
import AccountsPage from "./pages/accounts/AccountsPage";

// Utility function to track page views instead of page title visits
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

function Routing() {
  return (
    <BrowserRouter>
      <RouteChangeTracker />
      <NudgeContextProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up/:index?" element={<SignUpPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/overview" element={<OverviewPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route
            path="/profile/pi-details"
            element={<PersonalInformationForm />}
          />
          <Route
            path="/profile/address-details"
            element={<PersonalAddressForm />}
          />
          <Route
            path="/profile/change-password"
            element={<ChangePasswordRequest />}
          />
          <Route path="/accounts" element={<AccountsPage />} />
          <Route path="/smart-help" element={<SmartHelpPage />} />
          <Route path="/smart-coach" element={<SmartCoachPage />} />
          <Route path="/smart-coach/topic" element={<CoachContent />} />
          <Route
            path="/smart-coach/topic/module"
            element={<CoachContent renderModule />}
          />
          <Route path="/smart-coach/completed" element={<Completed />} />
          <Route path="/smart-solutions" element={<SmartSolutionsPage />} />
          <Route path="/log-dispute" element={<LogDisputePage />} />
        </Routes>
      </NudgeContextProvider>
    </BrowserRouter>
  );
}

export default Routing;
