import { useEffect, useState, useRef } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useSnackbar } from "notistack";
import CustomTheme from "../../../constants/Theme";
import { useAuthContext } from "../../../contexts/AuthContext";
import { getErrorMessage } from "../../../utils/errorHelper";
import SignUpController from "../../../controllers/SignUpController";
import ButtonLinkComp from "../../../components/base_components/ButtonLinkComp";
import ButtonComp from "../../../components/base_components/ButtonComp";
import SimplePopup from "../../../components/templates/SimplePopup";

const SecurityQuestionForm = ({ submitCallback, ...props }) => {
  const { abortApiRequests } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const canRetryRef = useRef(false);

  const [answers, setAnswers] = useState([]);
  const [remainingAttempts, setRemainingAttempts] = useState(99);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoadingAnswers, setIsLoadingAnswers] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFailedPopupOpen, setShowFailedPopupOpen] = useState(false);

  useEffect(() => {
    let mounted = true;

    resetAndReloadAnswers(mounted, true);

    return () => {
      mounted = false;
      abortApiRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const resetAndReloadAnswers = (mounted, checkAttempts) => {
    setIsLoadingAnswers(true);
    setSelectedIndex(-1);

    Promise.allSettled([getAnswers()])
      .then((responses) => {
        if (!mounted || responses[0].status === "rejected") return;

        const res = responses[0].value;

        setAnswers(res?.answers);
        setRemainingAttempts(res?.attempts_remaining);

        if (checkAttempts) {
          // Do not show popup if there are remaining attempts left
          if (res?.attempts_remaining > 0) return;

          // Show popup if no attempts remain
          handleshowFailedPopupOpen(res?.remainingAttempts);
        }
      })
      .finally(() => {
        if (!mounted) return;

        setIsLoadingAnswers(false);
      });
  };

  const getAnswers = async () => {
    return new Promise(async (res, rej) => {
      try {
        const response = isMobile
          ? await SignUpController.getSecurityQuestionsMobile()
          : await SignUpController.getSecurityQuestionsAddress();

        if (!(response?.answers?.length > 0)) {
          rej();
          enqueueSnackbar("No data received from server. Please try again", {
            variant: "error",
          });
        } else {
          res(response);
        }
      } catch (error) {
        const errMsg = getErrorMessage(error);
        rej(errMsg);

        // Check if request was aborted
        if (errMsg === null) return;

        enqueueSnackbar(errMsg, { variant: "error" });
      }
    });
  };

  const submitAnswer = async () => {
    try {
      setIsSubmitting(true);

      const answerId = answers[selectedIndex]?.id;
      const formData = {
        answer_ids: [answerId],
      };

      let response = {};

      try {
        response = isMobile
          ? await SignUpController.postSecurityQuestionsMobile(formData)
          : await SignUpController.postSecurityQuestionsAddress(formData);
      } catch (error) {
        const errMsg = getErrorMessage(error);

        if (
          errMsg.toLowerCase().includes("incorrect answer") ||
          errMsg.toLowerCase().includes("attempts exceeded")
        ) {
          response.isSuccess = false;
        } else {
          throw error;
        }
      }

      const result = {
        isCorrectAnswer: response.isSuccess || response.message === "success",
        remainingAttempts: remainingAttempts,
      };

      // Check if answer is correct
      if (result.isCorrectAnswer) {
        // Proceed to next question, or conclude validation screening
        if (isMobile) {
          setIsMobile(false);
        } else {
          submitCallback(true);
        }
      } else {
        // Show popup
        handleshowFailedPopupOpen(result.remainingAttempts);
      }
    } catch (error) {
      const errMsg = getErrorMessage(error);

      // Check if request was aborted
      if (errMsg === null) return;

      enqueueSnackbar(errMsg, { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSelect = (index) => {
    setSelectedIndex(index);
  };

  const handleshowFailedPopupOpen = (attempts) => {
    canRetryRef.current = attempts > 0;
    setShowFailedPopupOpen(true);
  };

  const handleshowFailedPopupClose = () => {
    if (!canRetryRef.current) {
      submitCallback(false);
      return;
    }

    setShowFailedPopupOpen(false);
    resetAndReloadAnswers(true, false);
  };

  return (
    <Container sx={{ padding: "0 !important" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} marginY={4}>
          <Typography component="h2" variant="pageTitle2Small">
            {isMobile
              ? "Please select phone numbers applicable to you"
              : "Please select addresses applicable to you"}
          </Typography>
        </Grid>

        {isLoadingAnswers && (
          <Grid item xs={12} container justifyContent="center">
            <CircularProgress size="5rem" />
          </Grid>
        )}

        {!isLoadingAnswers && (!answers || answers?.length <= 0) && (
          <Grid item xs={12}>
            <Typography component="p" variant="body1" textAlign="center">
              No answers received from server!
            </Typography>
          </Grid>
        )}

        {!isLoadingAnswers &&
          answers?.length > 0 &&
          answers.map((answer, index) => (
            <Grid key={index} item xs={12}>
              <ButtonLinkComp
                onClick={() => handleSelect(index)}
                endIcon={null}
                sx={{
                  backgroundColor:
                    selectedIndex === index
                      ? `${CustomTheme.palette.brandedTeal.main} !important`
                      : "unset",
                  maxWidth: "calc(100vw - 2rem)",
                }}
              >
                {answer.value}
              </ButtonLinkComp>
            </Grid>
          ))}

        <Grid item xs={12} marginTop={4}>
          <ButtonComp
            onClick={submitAnswer}
            disabled={
              isLoadingAnswers ||
              isSubmitting ||
              !answers ||
              answers?.length <= 0
            }
            isLoading={isSubmitting}
          >
            {isMobile ? "Continue" : "Submit"}
          </ButtonComp>
        </Grid>
      </Grid>

      <SimplePopup
        showPopup={showFailedPopupOpen}
        handleClose={handleshowFailedPopupClose}
        titleComp={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "4.6875rem",
              background: "rgba(213, 60, 50, 0.10)",
            }}
          >
            <ErrorIcon
              fontSize="large"
              color="error"
              sx={{ margin: "0.75363rem" }}
            />
            <Typography component="h5" variant="popupTitle" color="error">
              {canRetryRef.current ? "Uh oh..." : "Validation error"}
            </Typography>
          </Box>
        }
        contentComp={
          <Typography component="h6" variant="popupContent">
            {canRetryRef.current
              ? `Sorry, we could not validate your data. You have ${remainingAttempts} attempt(s) left`
              : "Sorry, we could not validate your data. A support ticket has been created, and a support agent will be in contact with you shortly"}
          </Typography>
        }
        actionText={canRetryRef.current ? "Retry" : "Okay"}
      />
    </Container>
  );
};

export default SecurityQuestionForm;
