import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import BackgroundImgFull from "../../assets/images/background_1_full.jpg";
import LogoFullImg from "../../assets/logos/logo_landing_small.png";

const LoginSignupContainer = ({
  pageTitle,
  pageHeading,
  pageTopContent,
  overwriteHandleBack,
  showBackButton,
  showLogo,
  ...props
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (overwriteHandleBack) {
      overwriteHandleBack();
      return;
    }

    navigate(-1);
  };

  return (
    <Grid container direction="row-reverse">
      {/* Background image */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: "100vh",
            opacity: 0.2,
            background: `url(${BackgroundImgFull})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        />
      </Grid>

      {/* Page components */}
      <Grid
        item
        xs={12}
        md={6}
        container
        alignContent="flex-start"
        sx={{
          paddingX: { xs: "1rem", md: "4rem" },
          top: 0,
          position: { xs: "absolute", md: "relative" },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100vh"
          alignContent="flex-start"
          paddingTop={showBackButton ? "1.5rem" : "5rem"}
        >
          {/* Back button */}
          {showBackButton && (
            <Box
              onClick={handleBack}
              sx={{
                cursor: "pointer",
                display: "flex",
                width: "fit-content",
                marginY: 2,
              }}
            >
              <ExpandCircleDownIcon
                sx={{
                  transform: "rotate(90deg)",
                  marginRight: "10px",
                }}
              />
              <Typography variant="body1" component="span">
                back
              </Typography>
            </Box>
          )}

          {/* ThreeSixty.me Logo */}
          {showLogo && (
            <img
              alt="full logo"
              src={LogoFullImg}
              style={{
                maxWidth: "600px",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            />
          )}

          {/* Page title */}
          {pageTitle}

          {/* Page contents */}
          <Box flex={1}>{props.children}</Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginSignupContainer;
