import ReactGA from "react-ga4";
import { appTitle } from "../constants/appData";
import { normaliseString } from "./generalHelper";

/**
 * Initiate Google Analytics tracking pixel.
 *
 * @param trackingId
 */
export const initGA = (trackingId) => {
  ReactGA.initialize(trackingId);
};

/**
 * Log a page view based on the current location.
 */
export const logPageView = () => {
  const pathName = window.location.pathname;
  const pathSplit = pathName?.split("/").filter(Boolean);
  const titleExt = pathSplit.length > 0 ? pathSplit.join(" ") : "Landing";
  const fullTitle = appTitle + " - " + normaliseString(titleExt);

  ReactGA.send({ hitType: "pageview", page: pathName, title: fullTitle });
};

/**
 * Log a signup event.
 */
export const logSignUpEvent = () => {
  ReactGA.event("sign_up");
};

/**
 * Log a login event.
 */
export const logLoginEvent = () => {
  ReactGA.event("login");
};
